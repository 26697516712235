import React, { useEffect, useState } from 'react';
import Typography from '@material-ui/core/Typography';
import {
  Button, CircularProgress, Box, Grid, TablePagination,
  FormHelperText, Select, FormControl, MenuItem,
} from '@material-ui/core';
import SortIcon from '@material-ui/icons/Sort';
import { LabelDisplayedRowsArgs } from '@material-ui/core/TablePagination';
import { format } from 'date-fns';
import { SideMenuLayout } from '../../../layouts';
import { withAuth, AuthedComponentProps } from '../../../auth/Authenticated';
import { SurveyResultList } from '../../../components/admin/SurveyResultList';
import { usePrivateApi } from '../../../api/useApi';
import {
  SurveyResultResponse, SurveyResultApi, SurveyDeliveryApi,
  SurveyDeliveryResponse, OrganizationApi, OrganizationResponse,
} from '../../../generated';

const Content = ({ session }: AuthedComponentProps) => {
  const [surveyResults, setSurveyResults] = useState<SurveyResultResponse[]>();
  const [page, setPage] = useState(0);
  const [visible, setVisible] = useState(false);
  const [total, setTotal] = useState(0);
  const [rows, setRows] = useState(10);
  const [orderByAlert, setOrderByAlert] = useState(false);
  const [filterByOrganizationId, setFilterByOrganizationId] = useState(0);
  const [filterByDeliveryId, setFilterByDeliveryId] = useState(0);
  const [organizations, setOrganizations] = useState<OrganizationResponse[]>();
  const [deliveries, setDeliveries] = useState<SurveyDeliveryResponse[]>();
  const { api: resultApi, loading: resultLoading } = usePrivateApi(SurveyResultApi);
  const { api: deliveryApi, loading: deliveryLoading } = usePrivateApi(SurveyDeliveryApi);
  const { api: organizationApi } = usePrivateApi(OrganizationApi);

  useEffect(() => {
    organizationApi.adminOrganizationsGet()
      .then(res => {
        setOrganizations(res.data);
      });
    deliveryApi.adminSurveyDeliveriesGet(filterByOrganizationId)
      .then(res => {
        setDeliveries(res.data);
      });
    const deliveryId = (filterByDeliveryId > 0) ? filterByDeliveryId : undefined;
    const organizationId = (filterByOrganizationId > 0) ? filterByOrganizationId : undefined;
    resultApi.adminSurveyResultsGet(undefined, organizationId, page, rows, orderByAlert, deliveryId)
      .then(res => {
        setTotal(res.data.pagination.totalElements);
        setSurveyResults(res.data.items);
        setVisible(true);
      });
  }, [session, page, orderByAlert, rows, filterByDeliveryId, filterByOrganizationId]);

  useEffect(() => {
    setFilterByDeliveryId(0);
  }, [filterByOrganizationId]);

  const onChangeRowsPerPage = (rowsPerPage: number) => {
    setPage(0);
    setRows(rowsPerPage);
  };

  const labelDisplayedRows = ({ from, to, count }: LabelDisplayedRowsArgs) => `${from}-${to === -1 ? count : to} : ${count}件中`;

  if ((resultLoading || deliveryLoading) && !visible) {
    return (
      <Grid container justify="center">
        <CircularProgress />
      </Grid>
    );
  }
  if (!surveyResults) {
    return <p>500 internal server error</p>;
  }
  return (
    <>
      <Box display="flex" flexDirection="column">
        <Box>
          <Typography variant="h4" component="h1">
            アンケート結果一覧
          </Typography>
        </Box>
        <Grid container>
          <Grid item xs={4}>
            <Button variant="contained" color="primary" onClick={() => setOrderByAlert(!orderByAlert)}>
              <SortIcon />
              アラート順にソートする
            </Button>
          </Grid>
          <Grid item xs={3}>
            <FormControl>
              <Select
                value={filterByOrganizationId}
                displayEmpty
                onChange={e => { setFilterByOrganizationId(Number(e.target.value)); }}
              >
                <MenuItem>-</MenuItem>
                {organizations ? organizations.map(o => (
                  <MenuItem value={o.id}>
                    {`${o.name}`}
                  </MenuItem>
                )) : <></>}
              </Select>
              <FormHelperText>
                会社ごとに絞り込み
              </FormHelperText>
            </FormControl>
          </Grid>
          <Grid item xs={3}>
            <FormControl>
              <Select
                value={filterByDeliveryId}
                displayEmpty
                onChange={e => { setFilterByDeliveryId(Number(e.target.value)); }}
              >
                <MenuItem>-</MenuItem>
                {deliveries ? deliveries.map(d => (
                  <MenuItem value={d.id}>
                    {`${d.surveyTitle} (${d.deliveryTimestamp && format(new Date(d.deliveryTimestamp), 'yyyy年MM月dd日')})`}
                  </MenuItem>
                )) : <></>}
              </Select>
              <FormHelperText>アンケート配信ごとに絞込み</FormHelperText>
            </FormControl>
          </Grid>
          <Grid item xs={2}>
            <Typography variant="h6" align="right">
              回答数:
              {total}
            </Typography>
          </Grid>
        </Grid>
      </Box>
      {(surveyResults.length === 0) && <p>回答結果がまだありません。</p>}
      <SurveyResultList surveyResults={surveyResults} />
      <TablePagination
        component="div"
        page={page}
        rowsPerPage={rows}
        count={total}
        labelRowsPerPage="表示件数"
        labelDisplayedRows={labelDisplayedRows}
        onChangePage={(_, p) => setPage(p)}
        onChangeRowsPerPage={e => onChangeRowsPerPage(Number(e.target.value))}
      />
    </>
  );
};

export const AdminSurveyResultIndexPage = SideMenuLayout(withAuth(Content));
