import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { MuiThemeProvider } from '@material-ui/core/styles';
import firebase from 'firebase';
import * as Sentry from '@sentry/browser';
import { CssBaseline } from '@material-ui/core';
import { NotFoundPage } from './pages/NotFoundPage';
import { config } from './auth/FirebaseConfig';
import { LoginPage } from './pages/auth/LoginPage';
import { withAuth } from './auth/Authenticated';
import { AppProvider } from './foundation/AppProvider';
import { NotVerifiedPage } from './pages/NotVerifiedPage';
import { TopPage } from './pages/TopPage';
import { LogoutPage } from './pages/auth/LogoutPage';
import { theme } from './theme';
import { PasswordResetPage } from './pages/auth/PasswordResetPage';
import { AccountActionPage } from './pages/auth/AccountActionPage';
import { RegisterAccountPage } from './pages/auth/RegisterAccountPage';
import { ScheduleFramePostPage } from './pages/counselor/schedule/ScheduleFramePostPage';
import { ScheduleFrameIndexPage } from './pages/counselor/schedule/ScheduleFrameIndexPage';
import { CounselorCounselingIndexPage } from './pages/counselor/counseling/CounselingIndexPage';
import { CounselorCounselingDetailPage } from './pages/counselor/counseling/CounselingDetailPage';
import { CounselorCounselingEditPage } from './pages/counselor/counseling/CounselingEditPage';
import { AdminCounselingIndexPage } from './pages/admin/counseling/CounselingIndexPage';
import { AdminCounselingReservationPage } from './pages/admin/counseling/CounselingReservationPage';
import { AdminCounselingDetailPage } from './pages/admin/counseling/CounselingDetailPage';
import { AdminCounselingEditPage } from './pages/admin/counseling/CounselingEditPage';
import { AdminCounselingReservationEditPage } from './pages/admin/counseling/CounselingReservationEditPage';
import { CounselorSurveyResultDetailPage } from './pages/counselor/survey_result';
import { AdminSurveyResultIndexPage, AdminSurveyResultDetailPage } from './pages/admin/survey_result';
import { AdminOrganizationIndexPage } from './pages/admin/OrganizationIndexPage';
import { AdminReadmePage } from './pages/admin/ReadmePage';
import { CounselingBulkCreatePage } from './pages/admin/counseling/CounselingBulkCreatePage';
import { CounselorRelationManagePage } from './pages/admin/CounselorRelationManagePage';

Sentry.init({ dsn: process.env.REACT_APP_SENTRY_DSN });
Sentry.configureScope(scope => {
  scope.setTag('module', 'admin');
  scope.setUser({ id: 'guest' });
});
firebase.initializeApp(config);

ReactDOM.render(
  <AppProvider>
    <MuiThemeProvider theme={theme}>
      <CssBaseline />
      <Router>
        <Switch>
          <Route exact path="/" component={withAuth(TopPage)} />
          <Route exact path="/auth/signin" component={LoginPage} />
          <Route exact path="/auth/signout" component={LogoutPage} />
          <Route exact path="/auth/register" component={RegisterAccountPage} />
          <Route exact path="/auth/password-reset" component={PasswordResetPage} />
          <Route exact path="/auth/action" component={AccountActionPage} />
          <Route exact path="/auth/not-verified" component={withAuth(NotVerifiedPage)} />
          <Route exact path="/counselor/schedule" component={withAuth(ScheduleFrameIndexPage)} />
          <Route exact path="/counselor/schedule/post" component={withAuth(ScheduleFramePostPage)} />
          <Route exact path="/counselor/counselings" component={withAuth(CounselorCounselingIndexPage)} />
          <Route exact path="/counselor/counselings/:id(\d+)" component={withAuth(CounselorCounselingDetailPage)} />
          <Route exact path="/counselor/counselings/:id(\d+)/edit" component={withAuth(CounselorCounselingEditPage)} />
          <Route exact path="/counselor/survey-results/:id(\d+)" component={withAuth(CounselorSurveyResultDetailPage)} />
          <Route exact path="/admin/readme" component={withAuth(AdminReadmePage)} />
          <Route exact path="/admin/organizations" component={withAuth(AdminOrganizationIndexPage)} />
          <Route exact path="/admin/counselings" component={withAuth(AdminCounselingIndexPage)} />
          <Route exact path="/admin/counselings/reservation" component={withAuth(AdminCounselingReservationPage)} />
          <Route exact path="/admin/counselings/:id(\d+)" component={withAuth(AdminCounselingDetailPage)} />
          <Route exact path="/admin/counselings/:id(\d+)/edit" component={withAuth(AdminCounselingEditPage)} />
          <Route exact path="/admin/counselings/reservation/:id(\d+)/edit" component={withAuth(AdminCounselingReservationEditPage)} />
          <Route exact path="/admin/survey-results" component={withAuth(AdminSurveyResultIndexPage)} />
          <Route exact path="/admin/survey-results/:id(\d+)" component={withAuth(AdminSurveyResultDetailPage)} />
          <Route exact path="/admin/counselings/bulk-new/:organizationId(\d+)" component={withAuth(CounselingBulkCreatePage)} />
          <Route exact path="/admin/counselor-relations/:organizationId(\d+)" component={withAuth(CounselorRelationManagePage)} />
          <Route component={NotFoundPage} />
        </Switch>
      </Router>
    </MuiThemeProvider>
  </AppProvider>,
  document.getElementById('app'),
);
