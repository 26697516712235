import React from 'react';
import {
  Paper, Table, TableHead, TableRow, TableCell, TableBody, Theme, makeStyles, createStyles, Button,
} from '@material-ui/core';
import useReactRouter from 'use-react-router';
import { format } from 'date-fns';
import ja from 'date-fns/locale/ja';
import { CounselingResponse } from '../../generated/api';

const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {
    width: '100%',
    marginTop: theme.spacing(3),
    overflowX: 'auto',
  },
  table: {
    minWidth: 650,
  },
  textButton: {
    '& > *': {
      margin: theme.spacing(1),
    },
  },
}));

interface IProps {
  counselings?: CounselingResponse[];
}

const adjustmentStatus = (counseling: CounselingResponse) => {
  if (counseling.adminComment ? !counseling.adminComment.includes('従業員連絡') : true) { return 'メール未送信'; }
  // 緊急連絡先emergencyContactは従業員回答で必須なので、nullなら未回答
  if (counseling.emergencyContact === null) { return '従業員回答待ち'; }
  if (counseling.reservationTimestamp === null) { return '手動調整が必要'; }
  if (counseling.adminComment ? !counseling.adminComment.includes('カウンセラー連絡') : true) { return 'カウンセラー未連絡'; }
  return '確定済み';
};

const Content = ({ counselings }: IProps) => {
  const classes = useStyles({});
  const { history } = useReactRouter();
  const pushToEditPath = (reservationId: number) => {
    history.push(`/admin/counselings/${reservationId}`);
  };

  return (
    <Paper className={classes.root}>
      <Table className={classes.table}>
        <TableHead>
          <TableRow>
            <TableCell>企業名</TableCell>
            <TableCell>従業員名</TableCell>
            <TableCell>予約日時</TableCell>
            <TableCell>調整ステータス</TableCell>
            <TableCell>連絡済みチェック</TableCell>
            <TableCell>アクション</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {counselings ? counselings.map(c => (
            <TableRow key={c.counselingReservationId}>
              <TableCell component="th" scope="row">{c.organizationName}</TableCell>
              <TableCell>{c.employeeName}</TableCell>
              <TableCell>{c.reservationTimestamp && format(new Date(c.reservationTimestamp), 'PPP(E) p', { locale: ja })}</TableCell>
              <TableCell>{adjustmentStatus(c)}</TableCell>
              <TableCell>
                <Button onClick={() => pushToEditPath(c.counselingReservationId)}>
                  詳細表示
                </Button>
              </TableCell>
            </TableRow>
          )) : <></>}
        </TableBody>
      </Table>
    </Paper>
  );
};

export const ReservationList = Content;
