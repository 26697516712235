import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Drawer,
  List,
  ListItem,
} from '@material-ui/core';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import * as Icons from '@material-ui/icons';
import { LinkForward } from '../common/LinkForward';
import { Logo } from '../common/Logo';


const drawerWidth = 240;

const useStyles = makeStyles(theme => ({
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  toolbar: {
    ...theme.mixins.toolbar,
    paddingBottom: '10px',
  },
}));

// TODO: ListItemのcomponentにインラインで関数を渡さないようにする
// https://material-ui.com/guides/composition/
export const CounselorNavigation = () => {
  const classes = useStyles({});
  return (
    <Drawer
      className={classes.drawer}
      variant="permanent"
      classes={{
        paper: classes.drawerPaper,
      }}
      anchor="left"
    >
      <div className={classes.toolbar}>
        <Logo />
      </div>
      <List>
        <ListItem
          button
          component={LinkForward('/counselor/schedule/post')}
        >
          <ListItemIcon><Icons.Schedule /></ListItemIcon>
          <ListItemText primary="スケジュール管理" />
        </ListItem>
        <ListItem
          button
          component={LinkForward('/counselor/schedule')}
        >
          <ListItemIcon><Icons.FullscreenOutlined /></ListItemIcon>
          <ListItemText primary="受付中日時一覧" />
        </ListItem>
        <ListItem
          button
          component={LinkForward('/counselor/counselings')}
        >
          <ListItemIcon><Icons.Assignment /></ListItemIcon>
          <ListItemText primary="面談記録" />
        </ListItem>
      </List>
    </Drawer>
  );
};
