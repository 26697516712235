import React from 'react';
import {
  FormControl,
  FormLabel,
  FormGroup,
  FormControlLabel,
  Checkbox,
  makeStyles,
  createStyles,
  Theme,
  Box,
} from '@material-ui/core';
import { ChoiceResponse } from '../../generated';

export interface Answer {
  id: number;
  questionId: number;
  value: string;
}

export interface CheckBoxQuestion {
  id: number;
  title: string;
  type: 'checkbox';
  choices: Array<ChoiceResponse>;
}

interface IProps {
  index: number;
  question: CheckBoxQuestion;
  answers: Answer[];
}

const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {
    width: '100%',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
}));

const Content = ({ index, question, answers }: IProps) => {
  const classes = useStyles();
  return (
    <FormControl className={classes.root}>
      <Box whiteSpace="pre-line">
        <FormLabel>
          {`Q${index + 1}: ${question.title}`}
        </FormLabel>
      </Box>
      <FormGroup row>
        {
          question.choices.map((choice: ChoiceResponse) => (
            <FormControlLabel
              control={<Checkbox value="3" color="primary" checked={answers.some(a => a.value === choice.value)} />}
              label={choice.title}
            />
          ))
        }
      </FormGroup>
    </FormControl>
  );
};

export const CheckBoxQuestionResult = Content;
