import React, { useState } from 'react';
import {
  Box, TextField, Grid, Link, Button, CircularProgress, FormHelperText,
} from '@material-ui/core';
import { Link as RouterLink } from 'react-router-dom';
import useForm from 'react-hook-form';
import firebase from 'firebase';
import { Logo } from '../../components/common/Logo';
import { Toast } from '../../components/common/Toast';
import { usePublicApi } from '../../api/useApi';
import { AccountApi } from '../../generated';

const isEmail = (email: string) => {
  // eslint-disable-next-line no-useless-escape
  const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
};

const Content = () => {
  const {
    register, handleSubmit, errors, getValues,
  } = useForm<{ email: string }>();
  const [loading, setLoading] = useState(false);
  const [toastMessage, setToastMessage] = useState();
  const { api } = usePublicApi(AccountApi);

  const emailRef = register({
    required: '必須項目です',
    validate: v => isEmail(v) || '正しいEメールアドレスではありません',
  });

  const onSubmit = async () => {
    setToastMessage(null);
    setLoading(true);

    const { email } = getValues();

    try {
      await api.accountsHead(email);
    } catch {
      setToastMessage('入力したメールアドレスは登録されていません');
      setLoading(false);
      return;
    }

    try {
      await firebase.auth().sendPasswordResetEmail(email, { url: `${window.location.origin}/auth/signin` });
    } catch (error) {
      switch (error.code) {
        case 'auth/user-not-found':
          setToastMessage('入力したメールアドレスは登録されていません');
          break;
        default:
          setToastMessage('送信時にエラーが発生しました');
      }
    }
    setToastMessage('パスワード再設定用メールを送信しました');
    setLoading(false);
  };

  return (
    <Grid
      container
      alignContent="center"
      justify="center"
      style={{ minHeight: '100vh' }}
    >
      <Grid
        item
        style={{
          background: 'white',
          borderRadius: '5px',
          width: '500px',
          marginBottom: '10px',
          padding: '20px',
        }}
      >
        <Box p={2} m={2}>
          <Box style={{ width: '100%', textAlign: 'center' }}>
            <Logo />
          </Box>
          <Box p={1}>
            <Grid container justify="center">
              入力したメールアドレス宛にパスワード再設定用URLを送信します
            </Grid>
          </Box>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Box p={1}>
              <TextField
                type="text"
                name="email"
                label="Email"
                inputRef={emailRef}
                fullWidth
              />
              {
                errors.email && (
                  <FormHelperText error>
                    {errors.email.message}
                  </FormHelperText>
                )
              }
            </Box>
            <Box p={1}>
              <Button
                type="submit"
                color="primary"
                fullWidth
                disabled={loading}
                style={{ lineHeight: '36px' }}
              >
                {loading && <CircularProgress size={20} />}
                パスワード再設定用メールを送信
              </Button>
            </Box>
          </form>
          <Box p={1} style={{ textAlign: 'center' }}>
            <Link to="/auth/signin" component={RouterLink}>ログインページに戻る</Link>
          </Box>
        </Box>
        <Toast
          message={toastMessage}
          duration={3000}
        />
      </Grid>
    </Grid>

  );
};

export const PasswordResetPage = Content;
