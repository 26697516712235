import React from 'react';
import {
  FormControl, FormLabel, makeStyles, createStyles, Theme, TextField, Box,
} from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {
    width: '100%',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
}));

export interface Answer {
  id: number;
  questionId: number;
  value: string;
}

export interface TextFieldQuestion {
  id: number;
  title: string;
  type: 'text';
}

interface IProps {
  index: number;
  question: TextFieldQuestion;
  answer?: Answer;
}

const Content = ({ index, question, answer }: IProps) => {
  const classes = useStyles();
  return (
    <FormControl className={classes.root}>
      <Box whiteSpace="pre-line">
        <FormLabel>
          {`Q${index + 1}: ${question.title}`}
        </FormLabel>
      </Box>
      <TextField
        multiline
        variant="outlined"
        rows="4"
        value={answer && answer.value}
      />
    </FormControl>
  );
};

export const TextFieldQuestionResult = Content;
