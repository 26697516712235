import React from 'react';
import useReactRouter from 'use-react-router';
import { VerifyEmail } from '../../components/auth/VerifyEmail';
import { ResetPassword } from '../../components/auth/ResetPassword';
import { MainLayout } from '../../layouts';
import { NotFoundPage } from '../NotFoundPage';

const Content = () => {
  const { location } = useReactRouter();
  const params = new URLSearchParams(location.search);
  const mode = params.get('mode');
  const actionCode = params.get('oobCode');

  if (!actionCode) return <NotFoundPage />;

  switch (mode) {
    case 'verifyEmail':
      return <VerifyEmail actionCode={actionCode} />;
    case 'resetPassword':
      return <ResetPassword actionCode={actionCode} />;
    default:
      return <NotFoundPage />;
  }
};

export const AccountActionPage = MainLayout(Content);
