import React, { useEffect, useState } from 'react';
import { Provider } from 'react-redux';
import { CircularProgress } from '@material-ui/core';
import { configureStore } from './Store';

interface IProps {
  children: any;
}

// redux-persistのrehydrationがreactのinitよりも遅いので
// 初期化を遅延させるためのコンポーネント
export const AppProvider: React.SFC<IProps> = ({ children }: IProps) => {
  const [rehydrated, setRehydrated] = useState(false);
  const [config, setConfig] = useState<ReturnType<typeof configureStore> | null>(null);

  useEffect(() => {
    const callback = () => {
      setRehydrated(true);
    };
    const tmp = configureStore(callback);
    setConfig(tmp);
  }, []);

  // persistの同期が終わるまでloaderを返す
  if (!rehydrated) {
    return <CircularProgress />;
  }
  // TODO: TypeGuard
  return (
    <Provider store={config!.store}>
      {children}
    </Provider>
  );
};
