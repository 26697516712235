// tslint:disable
/// <reference path="./custom.d.ts" />
/**
 * Admin API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as globalImportUrl from 'url';
import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base';

/**
 * 認証リクエスト
 * @export
 * @interface AccountAuthRequest
 */
export interface AccountAuthRequest {
    /**
     * 登録emailアドレス
     * @type {string}
     * @memberof AccountAuthRequest
     */
    email: string;
    /**
     * Firebase ID Token
     * @type {string}
     * @memberof AccountAuthRequest
     */
    token: string;
}
/**
 * アカウント正式登録リクエスト
 * @export
 * @interface AccountRegisterRequest
 */
export interface AccountRegisterRequest {
    /**
     * 登録emailアドレス
     * @type {string}
     * @memberof AccountRegisterRequest
     */
    email: string;
    /**
     * パスワード
     * @type {string}
     * @memberof AccountRegisterRequest
     */
    password: string;
}
/**
 * 認証レスポンス
 * @export
 * @interface AccountResponse
 */
export interface AccountResponse {
    /**
     * ユーザID(Firebaseのものではなくシステム上のID)
     * @type {number}
     * @memberof AccountResponse
     */
    userId: number;
    /**
     * メールアドレス
     * @type {string}
     * @memberof AccountResponse
     */
    email: string;
    /**
     * 氏名
     * @type {string}
     * @memberof AccountResponse
     */
    name: string;
    /**
     * ロール
     * @type {string}
     * @memberof AccountResponse
     */
    role: string;
    /**
     * Firebaseカスタムトークン
     * @type {string}
     * @memberof AccountResponse
     */
    token: string;
}
/**
 * アラート詳細
 * @export
 * @interface AlertDetailResponse
 */
export interface AlertDetailResponse {
    /**
     * アラートID
     * @type {number}
     * @memberof AlertDetailResponse
     */
    id: number;
    /**
     * 従業員ID
     * @type {number}
     * @memberof AlertDetailResponse
     */
    employeeId: number;
    /**
     * 従業員名
     * @type {string}
     * @memberof AlertDetailResponse
     */
    employeeName: string;
    /**
     * アラートラベル
     * @type {Array<string>}
     * @memberof AlertDetailResponse
     */
    labels: Array<string>;
    /**
     * アンケート結果ID
     * @type {number}
     * @memberof AlertDetailResponse
     */
    surveyResultId?: number;
    /**
     * 作成日時
     * @type {Date}
     * @memberof AlertDetailResponse
     */
    createdAt: Date;
}
/**
 * アラート
 * @export
 * @interface AlertResponse
 */
export interface AlertResponse {
    /**
     * アラートID
     * @type {number}
     * @memberof AlertResponse
     */
    id: number;
    /**
     * アラートラベル
     * @type {Array<string>}
     * @memberof AlertResponse
     */
    labels: Array<string>;
    /**
     * 従業員ID
     * @type {number}
     * @memberof AlertResponse
     */
    employeeId: number;
}
/**
 * アラート一覧
 * @export
 * @interface AlertsResponse
 */
export interface AlertsResponse {
    /**
     * 
     * @type {PaginationResponse}
     * @memberof AlertsResponse
     */
    pagination: PaginationResponse;
    /**
     * アラート
     * @type {Array<AlertResponse>}
     * @memberof AlertsResponse
     */
    alerts: Array<AlertResponse>;
}
/**
 * 設問回答
 * @export
 * @interface AnswerResponse
 */
export interface AnswerResponse {
    /**
     * 設問回答ID
     * @type {number}
     * @memberof AnswerResponse
     */
    id: number;
    /**
     * 設問ID
     * @type {number}
     * @memberof AnswerResponse
     */
    questionId: number;
    /**
     * 値
     * @type {string}
     * @memberof AnswerResponse
     */
    value: string;
}
/**
 * 面談調整一括作成リクエスト
 * @export
 * @interface BulkCreateCounselingRequest
 */
export interface BulkCreateCounselingRequest {
    /**
     * 従業員ID配列
     * @type {Array<number>}
     * @memberof BulkCreateCounselingRequest
     */
    employeeIds: Array<number>;
}
/**
 * アンケート設問の選択肢
 * @export
 * @interface ChoiceResponse
 */
export interface ChoiceResponse {
    /**
     * アンケート設問回答ID
     * @type {number}
     * @memberof ChoiceResponse
     */
    id: number;
    /**
     * 設問回答表示名
     * @type {string}
     * @memberof ChoiceResponse
     */
    title: string;
    /**
     * アンケート設問回答値
     * @type {string}
     * @memberof ChoiceResponse
     */
    value: string;
}
/**
 * 今期契約情報レスポンス
 * @export
 * @interface ContractResponse
 */
export interface ContractResponse {
    /**
     * 組織ID
     * @type {number}
     * @memberof ContractResponse
     */
    organizationId: number;
    /**
     * 契約済面談回数
     * @type {number}
     * @memberof ContractResponse
     */
    contractedCounselingsCount: number;
    /**
     * 予約済み面談数
     * @type {number}
     * @memberof ContractResponse
     */
    reservedCounselingsCount: number;
    /**
     * 実施済み面談数
     * @type {number}
     * @memberof ContractResponse
     */
    usedCounselingsCount: number;
    /**
     * 契約開始日
     * @type {string}
     * @memberof ContractResponse
     */
    startDate: string;
    /**
     * 契約終了日
     * @type {string}
     * @memberof ContractResponse
     */
    endDate: string;
}
/**
 * アドバイス項目
 * @export
 * @enum {string}
 */
export enum CounselingAdvice {
    Body = 'body',
    Mental = 'mental',
    Relation = 'relation',
    Other = 'other',
    ReportDisagreement = 'reportDisagreement'
}

/**
 * 面談記録
 * @export
 * @interface CounselingDetailResponse
 */
export interface CounselingDetailResponse {
    /**
     * 面談予約ID
     * @type {number}
     * @memberof CounselingDetailResponse
     */
    counselingReservationId: number;
    /**
     * 調査結果ID
     * @type {number}
     * @memberof CounselingDetailResponse
     */
    surveyResultId?: number;
    /**
     * 従業員ID
     * @type {number}
     * @memberof CounselingDetailResponse
     */
    employeeId: number;
    /**
     * 従業員Email
     * @type {string}
     * @memberof CounselingDetailResponse
     */
    employeeEmail: string;
    /**
     * カウンセラーID
     * @type {number}
     * @memberof CounselingDetailResponse
     */
    counselorId?: number;
    /**
     * 面談予定日時
     * @type {Date}
     * @memberof CounselingDetailResponse
     */
    reservationTimestamp?: Date;
    /**
     * 面談実施日時
     * @type {Date}
     * @memberof CounselingDetailResponse
     */
    counseledTimestamp?: Date;
    /**
     * 従業員名
     * @type {string}
     * @memberof CounselingDetailResponse
     */
    employeeName: string;
    /**
     * アンケート名
     * @type {string}
     * @memberof CounselingDetailResponse
     */
    surveyTitle?: string;
    /**
     * 面談コメント
     * @type {string}
     * @memberof CounselingDetailResponse
     */
    comment?: string;
    /**
     * 面談実施ステータス
     * @type {string}
     * @memberof CounselingDetailResponse
     */
    status: CounselingDetailResponseStatusEnum;
    /**
     * 面談の内容
     * @type {Array<CounselingResultItem>}
     * @memberof CounselingDetailResponse
     */
    counselingResultItems: Array<CounselingResultItem>;
    /**
     * 次回面談の日時
     * @type {Date}
     * @memberof CounselingDetailResponse
     */
    nextCounselingTimestamp?: Date;
    /**
     * 従業員コメント
     * @type {string}
     * @memberof CounselingDetailResponse
     */
    employeeComment?: string;
    /**
     * 従業員緊急連絡先
     * @type {string}
     * @memberof CounselingDetailResponse
     */
    emergencyContact?: string;
    /**
     * 管理者コメント
     * @type {string}
     * @memberof CounselingDetailResponse
     */
    adminComment?: string;
}

/**
    * @export
    * @enum {string}
    */
export enum CounselingDetailResponseStatusEnum {
    Reservation = 'reservation',
    Cancel = 'cancel',
    Counseled = 'counseled'
}

/**
 * 面談記録作成フォームの要素
 * @export
 * @interface CounselingFormItem
 */
export interface CounselingFormItem {
    /**
     * 
     * @type {string}
     * @memberof CounselingFormItem
     */
    code: string;
    /**
     * 
     * @type {string}
     * @memberof CounselingFormItem
     */
    displayName: string;
}
/**
 * 面談記録フォーム
 * @export
 * @interface CounselingFormResponse
 */
export interface CounselingFormResponse {
    /**
     * 面談のきっかけ
     * @type {Array<CounselingFormItem>}
     * @memberof CounselingFormResponse
     */
    counselingOpportunity: Array<CounselingFormItem>;
    /**
     * アドバイス項目
     * @type {Array<CounselingFormItem>}
     * @memberof CounselingFormResponse
     */
    counselingAdvice: Array<CounselingFormItem>;
    /**
     * 主なストレス要因
     * @type {Array<CounselingFormItem>}
     * @memberof CounselingFormResponse
     */
    mainStressor: Array<CounselingFormItem>;
    /**
     * 緊急度
     * @type {Array<CounselingFormItem>}
     * @memberof CounselingFormResponse
     */
    urgency: Array<CounselingFormItem>;
    /**
     * 離職する可能性
     * @type {Array<CounselingFormItem>}
     * @memberof CounselingFormResponse
     */
    retirementPossibility: Array<CounselingFormItem>;
    /**
     * 休職する可能性
     * @type {Array<CounselingFormItem>}
     * @memberof CounselingFormResponse
     */
    absencePossibility: Array<CounselingFormItem>;
    /**
     * 産業医面談
     * @type {Array<CounselingFormItem>}
     * @memberof CounselingFormResponse
     */
    industrialDoctorCounseling: Array<CounselingFormItem>;
    /**
     * 受診勧奨
     * @type {Array<CounselingFormItem>}
     * @memberof CounselingFormResponse
     */
    encourageConsultation: Array<CounselingFormItem>;
    /**
     * 人事対応
     * @type {Array<CounselingFormItem>}
     * @memberof CounselingFormResponse
     */
    hrSupport: Array<CounselingFormItem>;
    /**
     * 組織対応
     * @type {Array<CounselingFormItem>}
     * @memberof CounselingFormResponse
     */
    organizationalSupport: Array<CounselingFormItem>;
    /**
     * 業務上の配慮
     * @type {Array<CounselingFormItem>}
     * @memberof CounselingFormResponse
     */
    businessConsiderations: Array<CounselingFormItem>;
    /**
     * 人事労務担当者への直接報告
     * @type {Array<CounselingFormItem>}
     * @memberof CounselingFormResponse
     */
    reportToHr: Array<CounselingFormItem>;
    /**
     * 次回面談
     * @type {Array<CounselingFormItem>}
     * @memberof CounselingFormResponse
     */
    nextCounseling: Array<CounselingFormItem>;
}
/**
 * 面談記録
 * @export
 * @interface CounselingResponse
 */
export interface CounselingResponse {
    /**
     * 組織ID
     * @type {number}
     * @memberof CounselingResponse
     */
    organizationId: number;
    /**
     * 組織名
     * @type {string}
     * @memberof CounselingResponse
     */
    organizationName: string;
    /**
     * 面談予約ID
     * @type {number}
     * @memberof CounselingResponse
     */
    counselingReservationId: number;
    /**
     * 調査結果ID
     * @type {number}
     * @memberof CounselingResponse
     */
    surveyResultId?: number;
    /**
     * 従業員ID
     * @type {number}
     * @memberof CounselingResponse
     */
    employeeId?: number;
    /**
     * 従業員Email
     * @type {string}
     * @memberof CounselingResponse
     */
    employeeEmail: string;
    /**
     * カウンセラーID
     * @type {number}
     * @memberof CounselingResponse
     */
    counselorId?: number;
    /**
     * 面談予定日時
     * @type {Date}
     * @memberof CounselingResponse
     */
    reservationTimestamp?: Date;
    /**
     * 面談実施日時
     * @type {Date}
     * @memberof CounselingResponse
     */
    counseledTimestamp?: Date;
    /**
     * 従業員名
     * @type {string}
     * @memberof CounselingResponse
     */
    employeeName: string;
    /**
     * アンケート名
     * @type {string}
     * @memberof CounselingResponse
     */
    surveyTitle: string;
    /**
     * 面談コメント
     * @type {string}
     * @memberof CounselingResponse
     */
    comment?: string;
    /**
     * 面談実施ステータス
     * @type {string}
     * @memberof CounselingResponse
     */
    status: CounselingResponseStatusEnum;
    /**
     * 従業員コメント
     * @type {string}
     * @memberof CounselingResponse
     */
    employeeComment?: string;
    /**
     * 従業員緊急連絡先
     * @type {string}
     * @memberof CounselingResponse
     */
    emergencyContact?: string;
    /**
     * 管理者コメント
     * @type {string}
     * @memberof CounselingResponse
     */
    adminComment?: string;
    /**
     * 時間枠ID
     * @type {number}
     * @memberof CounselingResponse
     */
    scheduleFrameId?: number;
}

/**
    * @export
    * @enum {string}
    */
export enum CounselingResponseStatusEnum {
    Reservation = 'reservation',
    Cancel = 'cancel',
    Counseled = 'counseled'
}

/**
 * 面談結果要素
 * @export
 * @interface CounselingResultItem
 */
export interface CounselingResultItem {
    /**
     * 質問コード
     * @type {string}
     * @memberof CounselingResultItem
     */
    titleCode: string;
    /**
     * 質問表示名
     * @type {string}
     * @memberof CounselingResultItem
     */
    titleDisplayName: string;
    /**
     * 面談の結果
     * @type {Array<CounselingFormItem>}
     * @memberof CounselingResultItem
     */
    answer: Array<CounselingFormItem>;
    /**
     * 面談フォーム種別
     * @type {string}
     * @memberof CounselingResultItem
     */
    formType?: CounselingResultItemFormTypeEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum CounselingResultItemFormTypeEnum {
    Checkbox = 'checkbox',
    Pulldown = 'pulldown',
    Text = 'text'
}

/**
 * カウンセラーレスポンス
 * @export
 * @interface CounselorResponse
 */
export interface CounselorResponse {
    /**
     * ID
     * @type {number}
     * @memberof CounselorResponse
     */
    id?: number;
    /**
     * カウンセラー名
     * @type {string}
     * @memberof CounselorResponse
     */
    name?: string;
}
/**
 * 従業員レスポンス
 * @export
 * @interface EmployeeResponse
 */
export interface EmployeeResponse {
    /**
     * 従業員ID(mindex内管理用ID)
     * @type {number}
     * @memberof EmployeeResponse
     */
    id: number;
    /**
     * 従業員名
     * @type {string}
     * @memberof EmployeeResponse
     */
    employeeName: string;
    /**
     * 社員番号(組織内での管理番号)
     * @type {string}
     * @memberof EmployeeResponse
     */
    employeeNumber?: string;
    /**
     * 年齢
     * @type {number}
     * @memberof EmployeeResponse
     */
    age?: number;
    /**
     * 入社年月日
     * @type {string}
     * @memberof EmployeeResponse
     */
    joinedDate?: string;
    /**
     * 従業員Eメールアドレス
     * @type {string}
     * @memberof EmployeeResponse
     */
    email: string;
}
/**
 * 従業員一覧のレスポンス
 * @export
 * @interface EmployeesResponse
 */
export interface EmployeesResponse {
    /**
     * 
     * @type {PaginationResponse}
     * @memberof EmployeesResponse
     */
    pagination: PaginationResponse;
    /**
     * 
     * @type {Array<EmployeeResponse>}
     * @memberof EmployeesResponse
     */
    items: Array<EmployeeResponse>;
}
/**
 * エラー
 * @export
 * @interface ErrorResponse
 */
export interface ErrorResponse {
    /**
     * エラーコード
     * @type {number}
     * @memberof ErrorResponse
     */
    code: number;
    /**
     * エラーメッセージ
     * @type {string}
     * @memberof ErrorResponse
     */
    message: string;
}
/**
 * 主なストレス要因
 * @export
 * @enum {string}
 */
export enum MainStressor {
    BossRelation = 'bossRelation',
    ColleagueRelation = 'colleagueRelation',
    SubordinateRelation = 'subordinateRelation',
    OutsideRelation = 'outsideRelation',
    PersonnelSystem = 'personnelSystem',
    Salary = 'salary',
    CorporateCulture = 'corporateCulture',
    WorkAmount = 'workAmount',
    WorkIrregularity = 'workIrregularity',
    WorkQuality = 'workQuality',
    Welfare = 'welfare',
    IndustryFuture = 'industryFuture',
    PoorHealth = 'poorHealth',
    ParentingCaregiver = 'parentingCaregiver',
    PrivateReason = 'privateReason',
    Nothing = 'nothing',
    ReportDisagreement = 'reportDisagreement'
}

/**
 * 組織レスポンス
 * @export
 * @interface OrganizationResponse
 */
export interface OrganizationResponse {
    /**
     * ID
     * @type {number}
     * @memberof OrganizationResponse
     */
    id?: number;
    /**
     * 名称
     * @type {string}
     * @memberof OrganizationResponse
     */
    name?: string;
}
/**
 * アンケート結果一覧
 * @export
 * @interface PaginatedSurveyResultsResponse
 */
export interface PaginatedSurveyResultsResponse {
    /**
     * 
     * @type {PaginationResponse}
     * @memberof PaginatedSurveyResultsResponse
     */
    pagination: PaginationResponse;
    /**
     * 
     * @type {Array<SurveyResultResponse>}
     * @memberof PaginatedSurveyResultsResponse
     */
    items: Array<SurveyResultResponse>;
}
/**
 * 
 * @export
 * @interface PaginationResponse
 */
export interface PaginationResponse {
    /**
     * ページ番号
     * @type {number}
     * @memberof PaginationResponse
     */
    pageNumber: number;
    /**
     * 合計要素数
     * @type {number}
     * @memberof PaginationResponse
     */
    totalElements: number;
    /**
     * 合計ページ数
     * @type {number}
     * @memberof PaginationResponse
     */
    totalPages: number;
}
/**
 * アンケート設問
 * @export
 * @interface QuestionResponse
 */
export interface QuestionResponse {
    /**
     * アンケート設問ID
     * @type {number}
     * @memberof QuestionResponse
     */
    id: number;
    /**
     * 設問タイトル
     * @type {string}
     * @memberof QuestionResponse
     */
    title: string;
    /**
     * アンケート設問種別
     * @type {string}
     * @memberof QuestionResponse
     */
    type: QuestionResponseTypeEnum;
    /**
     * 設問回答必須有無
     * @type {boolean}
     * @memberof QuestionResponse
     */
    required?: boolean;
    /**
     * アンケート設問回答選択肢
     * @type {Array<ChoiceResponse>}
     * @memberof QuestionResponse
     */
    choices?: Array<ChoiceResponse>;
}

/**
    * @export
    * @enum {string}
    */
export enum QuestionResponseTypeEnum {
    Radio = 'radio',
    Checkbox = 'checkbox',
    Slider = 'slider',
    Text = 'text'
}

/**
 * スケジュール枠作成リクエスト
 * @export
 * @interface ScheduleFrameRequest
 */
export interface ScheduleFrameRequest {
    /**
     * 対象日
     * @type {string}
     * @memberof ScheduleFrameRequest
     */
    scheduleDate: string;
    /**
     * 枠時間
     * @type {number}
     * @memberof ScheduleFrameRequest
     */
    frameTime: number;
}
/**
 * スケジュール枠レスポンス
 * @export
 * @interface ScheduleFrameResponse
 */
export interface ScheduleFrameResponse {
    /**
     * ID
     * @type {number}
     * @memberof ScheduleFrameResponse
     */
    id: number;
    /**
     * カウンセラーID
     * @type {number}
     * @memberof ScheduleFrameResponse
     */
    counselorId: number;
    /**
     * 対象日
     * @type {string}
     * @memberof ScheduleFrameResponse
     */
    scheduleDate: string;
    /**
     * 枠時間
     * @type {number}
     * @memberof ScheduleFrameResponse
     */
    frameTime: number;
}
/**
 * スケジュール枠一括作成リクエスト
 * @export
 * @interface ScheduleFramesRequest
 */
export interface ScheduleFramesRequest {
    /**
     * スケジュール枠
     * @type {Array<ScheduleFrameRequest>}
     * @memberof ScheduleFramesRequest
     */
    scheduleFrames: Array<ScheduleFrameRequest>;
}
/**
 * スケジュール枠一覧レスポンス
 * @export
 * @interface ScheduleFramesResponse
 */
export interface ScheduleFramesResponse {
    /**
     * スケジュール枠
     * @type {Array<ScheduleFrameResponse>}
     * @memberof ScheduleFramesResponse
     */
    scheduleFrames?: Array<ScheduleFrameResponse>;
}
/**
 * アンケート配信
 * @export
 * @interface SurveyDeliveryResponse
 */
export interface SurveyDeliveryResponse {
    /**
     * アンケート配信ID
     * @type {number}
     * @memberof SurveyDeliveryResponse
     */
    id: number;
    /**
     * アンケート配信UUID
     * @type {string}
     * @memberof SurveyDeliveryResponse
     */
    uuid: string;
    /**
     * アンケートID
     * @type {number}
     * @memberof SurveyDeliveryResponse
     */
    surveyId: number;
    /**
     * アンケートタイトル
     * @type {string}
     * @memberof SurveyDeliveryResponse
     */
    surveyTitle: string;
    /**
     * 配信日時
     * @type {Date}
     * @memberof SurveyDeliveryResponse
     */
    deliveryTimestamp: Date;
    /**
     * 配信状態
     * @type {boolean}
     * @memberof SurveyDeliveryResponse
     */
    deliveryStatus: boolean;
}
/**
 * アンケート結果詳細
 * @export
 * @interface SurveyResultDetailResponse
 */
export interface SurveyResultDetailResponse {
    /**
     * アンケート結果ID
     * @type {number}
     * @memberof SurveyResultDetailResponse
     */
    id: number;
    /**
     * アンケートタイトル
     * @type {string}
     * @memberof SurveyResultDetailResponse
     */
    title?: string;
    /**
     * 従業員名
     * @type {string}
     * @memberof SurveyResultDetailResponse
     */
    employeeName?: string;
    /**
     * 従業員Eメールアドレス
     * @type {string}
     * @memberof SurveyResultDetailResponse
     */
    employeeEmail?: string;
    /**
     * 設問
     * @type {Array<QuestionResponse>}
     * @memberof SurveyResultDetailResponse
     */
    questions: Array<QuestionResponse>;
    /**
     * 設問回答
     * @type {Array<AnswerResponse>}
     * @memberof SurveyResultDetailResponse
     */
    answers: Array<AnswerResponse>;
    /**
     * 
     * @type {AlertResponse}
     * @memberof SurveyResultDetailResponse
     */
    alert?: AlertResponse;
}
/**
 * アンケート結果
 * @export
 * @interface SurveyResultResponse
 */
export interface SurveyResultResponse {
    /**
     * アンケート結果ID
     * @type {number}
     * @memberof SurveyResultResponse
     */
    id: number;
    /**
     * アンケートタイトル
     * @type {string}
     * @memberof SurveyResultResponse
     */
    title: string;
    /**
     * 従業員名
     * @type {string}
     * @memberof SurveyResultResponse
     */
    employeeName: string;
    /**
     * 従業員Eメールアドレス
     * @type {string}
     * @memberof SurveyResultResponse
     */
    employeeEmail?: string;
    /**
     * 回答開始日時
     * @type {Date}
     * @memberof SurveyResultResponse
     */
    startTimestamp: Date;
    /**
     * 回答完了日時
     * @type {Date}
     * @memberof SurveyResultResponse
     */
    finishTimestamp?: Date;
    /**
     * アラート
     * @type {boolean}
     * @memberof SurveyResultResponse
     */
    alerted: boolean;
}
/**
 * アンケート結果一覧
 * @export
 * @interface SurveyResultsResponse
 */
export interface SurveyResultsResponse {
    /**
     * 
     * @type {Array<SurveyResultResponse>}
     * @memberof SurveyResultsResponse
     */
    items: Array<SurveyResultResponse>;
}
/**
 * 面談記録更新リクエスト
 * @export
 * @interface UpdateCounselingRequest
 */
export interface UpdateCounselingRequest {
    /**
     * アンケート結果ID
     * @type {number}
     * @memberof UpdateCounselingRequest
     */
    surveyResultId?: number;
    /**
     * 面談コメント
     * @type {string}
     * @memberof UpdateCounselingRequest
     */
    comment?: string;
    /**
     * 面談実施日時
     * @type {Date}
     * @memberof UpdateCounselingRequest
     */
    counseledTimestamp?: Date;
    /**
     * 従業員ID
     * @type {number}
     * @memberof UpdateCounselingRequest
     */
    employeeId?: number;
    /**
     * 面談のきっかけ
     * @type {string}
     * @memberof UpdateCounselingRequest
     */
    counselingOpportunity: UpdateCounselingRequestCounselingOpportunityEnum;
    /**
     * アドバイス項目
     * @type {Array<CounselingAdvice>}
     * @memberof UpdateCounselingRequest
     */
    counselingAdviceList: Array<CounselingAdvice>;
    /**
     * 主なストレス要因
     * @type {Array<MainStressor>}
     * @memberof UpdateCounselingRequest
     */
    mainStressorList: Array<MainStressor>;
    /**
     * 緊急度
     * @type {string}
     * @memberof UpdateCounselingRequest
     */
    urgency: UpdateCounselingRequestUrgencyEnum;
    /**
     * 離職する可能性
     * @type {string}
     * @memberof UpdateCounselingRequest
     */
    retirementPossibility: UpdateCounselingRequestRetirementPossibilityEnum;
    /**
     * 休職する可能性
     * @type {string}
     * @memberof UpdateCounselingRequest
     */
    absencePossibility: UpdateCounselingRequestAbsencePossibilityEnum;
    /**
     * 産業医面談
     * @type {string}
     * @memberof UpdateCounselingRequest
     */
    industrialDoctorCounseling: UpdateCounselingRequestIndustrialDoctorCounselingEnum;
    /**
     * 受診勧奨
     * @type {string}
     * @memberof UpdateCounselingRequest
     */
    encourageConsultation: UpdateCounselingRequestEncourageConsultationEnum;
    /**
     * 人事対応
     * @type {string}
     * @memberof UpdateCounselingRequest
     */
    hrSupport: UpdateCounselingRequestHrSupportEnum;
    /**
     * 組織対応
     * @type {string}
     * @memberof UpdateCounselingRequest
     */
    organizationalSupport: UpdateCounselingRequestOrganizationalSupportEnum;
    /**
     * 業務上の配慮
     * @type {string}
     * @memberof UpdateCounselingRequest
     */
    businessConsiderations: UpdateCounselingRequestBusinessConsiderationsEnum;
    /**
     * 人事労務担当者への直接報告
     * @type {string}
     * @memberof UpdateCounselingRequest
     */
    reportToHr: UpdateCounselingRequestReportToHrEnum;
    /**
     * 次回面談
     * @type {string}
     * @memberof UpdateCounselingRequest
     */
    nextCounseling: UpdateCounselingRequestNextCounselingEnum;
    /**
     * 次回面談の日時
     * @type {Date}
     * @memberof UpdateCounselingRequest
     */
    nextCounselingTimestamp?: Date;
}

/**
    * @export
    * @enum {string}
    */
export enum UpdateCounselingRequestCounselingOpportunityEnum {
    Questionnaire = 'questionnaire',
    SelfRecommendation = 'selfRecommendation',
    ColleagueRecommendation = 'colleagueRecommendation',
    BossRecommendation = 'bossRecommendation',
    HrRecommendation = 'hrRecommendation',
    Other = 'other'
}
/**
    * @export
    * @enum {string}
    */
export enum UpdateCounselingRequestUrgencyEnum {
    High = 'high',
    Medium = 'medium',
    Low = 'low'
}
/**
    * @export
    * @enum {string}
    */
export enum UpdateCounselingRequestRetirementPossibilityEnum {
    High = 'high',
    Medium = 'medium',
    Low = 'low'
}
/**
    * @export
    * @enum {string}
    */
export enum UpdateCounselingRequestAbsencePossibilityEnum {
    High = 'high',
    Medium = 'medium',
    Low = 'low'
}
/**
    * @export
    * @enum {string}
    */
export enum UpdateCounselingRequestIndustrialDoctorCounselingEnum {
    Necessary = 'necessary',
    Unnecessary = 'unnecessary'
}
/**
    * @export
    * @enum {string}
    */
export enum UpdateCounselingRequestEncourageConsultationEnum {
    Necessary = 'necessary',
    Unnecessary = 'unnecessary'
}
/**
    * @export
    * @enum {string}
    */
export enum UpdateCounselingRequestHrSupportEnum {
    Necessary = 'necessary',
    Unnecessary = 'unnecessary'
}
/**
    * @export
    * @enum {string}
    */
export enum UpdateCounselingRequestOrganizationalSupportEnum {
    Necessary = 'necessary',
    Unnecessary = 'unnecessary'
}
/**
    * @export
    * @enum {string}
    */
export enum UpdateCounselingRequestBusinessConsiderationsEnum {
    Necessary = 'necessary',
    Unnecessary = 'unnecessary'
}
/**
    * @export
    * @enum {string}
    */
export enum UpdateCounselingRequestReportToHrEnum {
    Necessary = 'necessary',
    Unnecessary = 'unnecessary'
}
/**
    * @export
    * @enum {string}
    */
export enum UpdateCounselingRequestNextCounselingEnum {
    Necessary = 'necessary',
    Unnecessary = 'unnecessary'
}

/**
 * 予約更新リクエスト
 * @export
 * @interface UpdateCounselingReservationRequest
 */
export interface UpdateCounselingReservationRequest {
    /**
     * コメント
     * @type {string}
     * @memberof UpdateCounselingReservationRequest
     */
    comment?: string;
    /**
     * 予約日
     * @type {string}
     * @memberof UpdateCounselingReservationRequest
     */
    reservationDate?: string;
    /**
     * 予約時間帯(0-23h)
     * @type {number}
     * @memberof UpdateCounselingReservationRequest
     */
    reservationHour?: number;
    /**
     * カウンセラーID
     * @type {number}
     * @memberof UpdateCounselingReservationRequest
     */
    counselorId?: number;
    /**
     * 管理者コメント
     * @type {string}
     * @memberof UpdateCounselingReservationRequest
     */
    adminComment?: string;
}

/**
 * AccountApi - axios parameter creator
 * @export
 */
export const AccountApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary アカウントを認証する
         * @param {AccountAuthRequest} accountAuthRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountsAuthPost(accountAuthRequest: AccountAuthRequest, options: any = {}): RequestArgs {
            // verify required parameter 'accountAuthRequest' is not null or undefined
            if (accountAuthRequest === null || accountAuthRequest === undefined) {
                throw new RequiredError('accountAuthRequest','Required parameter accountAuthRequest was null or undefined when calling accountsAuthPost.');
            }
            const localVarPath = `/accounts/auth`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (<any>"AccountAuthRequest" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(accountAuthRequest !== undefined ? accountAuthRequest : {}) : (accountAuthRequest || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary アカウントが存在するか確認する
         * @param {string} email メールアドレス
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountsHead(email: string, options: any = {}): RequestArgs {
            // verify required parameter 'email' is not null or undefined
            if (email === null || email === undefined) {
                throw new RequiredError('email','Required parameter email was null or undefined when calling accountsHead.');
            }
            const localVarPath = `/accounts`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'HEAD', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (email !== undefined) {
                localVarQueryParameter['email'] = email;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 仮登録アカウントを正式に登録する
         * @param {AccountRegisterRequest} accountRegisterRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountsRegisterPost(accountRegisterRequest: AccountRegisterRequest, options: any = {}): RequestArgs {
            // verify required parameter 'accountRegisterRequest' is not null or undefined
            if (accountRegisterRequest === null || accountRegisterRequest === undefined) {
                throw new RequiredError('accountRegisterRequest','Required parameter accountRegisterRequest was null or undefined when calling accountsRegisterPost.');
            }
            const localVarPath = `/accounts/register`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (<any>"AccountRegisterRequest" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(accountRegisterRequest !== undefined ? accountRegisterRequest : {}) : (accountRegisterRequest || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AccountApi - functional programming interface
 * @export
 */
export const AccountApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary アカウントを認証する
         * @param {AccountAuthRequest} accountAuthRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountsAuthPost(accountAuthRequest: AccountAuthRequest, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<AccountResponse> {
            const localVarAxiosArgs = AccountApiAxiosParamCreator(configuration).accountsAuthPost(accountAuthRequest, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary アカウントが存在するか確認する
         * @param {string} email メールアドレス
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountsHead(email: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
            const localVarAxiosArgs = AccountApiAxiosParamCreator(configuration).accountsHead(email, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary 仮登録アカウントを正式に登録する
         * @param {AccountRegisterRequest} accountRegisterRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountsRegisterPost(accountRegisterRequest: AccountRegisterRequest, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<AccountResponse> {
            const localVarAxiosArgs = AccountApiAxiosParamCreator(configuration).accountsRegisterPost(accountRegisterRequest, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * AccountApi - factory interface
 * @export
 */
export const AccountApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @summary アカウントを認証する
         * @param {AccountAuthRequest} accountAuthRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountsAuthPost(accountAuthRequest: AccountAuthRequest, options?: any) {
            return AccountApiFp(configuration).accountsAuthPost(accountAuthRequest, options)(axios, basePath);
        },
        /**
         * 
         * @summary アカウントが存在するか確認する
         * @param {string} email メールアドレス
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountsHead(email: string, options?: any) {
            return AccountApiFp(configuration).accountsHead(email, options)(axios, basePath);
        },
        /**
         * 
         * @summary 仮登録アカウントを正式に登録する
         * @param {AccountRegisterRequest} accountRegisterRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountsRegisterPost(accountRegisterRequest: AccountRegisterRequest, options?: any) {
            return AccountApiFp(configuration).accountsRegisterPost(accountRegisterRequest, options)(axios, basePath);
        },
    };
};

/**
 * AccountApi - object-oriented interface
 * @export
 * @class AccountApi
 * @extends {BaseAPI}
 */
export class AccountApi extends BaseAPI {
    /**
     * 
     * @summary アカウントを認証する
     * @param {AccountAuthRequest} accountAuthRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApi
     */
    public accountsAuthPost(accountAuthRequest: AccountAuthRequest, options?: any) {
        return AccountApiFp(this.configuration).accountsAuthPost(accountAuthRequest, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary アカウントが存在するか確認する
     * @param {string} email メールアドレス
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApi
     */
    public accountsHead(email: string, options?: any) {
        return AccountApiFp(this.configuration).accountsHead(email, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary 仮登録アカウントを正式に登録する
     * @param {AccountRegisterRequest} accountRegisterRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApi
     */
    public accountsRegisterPost(accountRegisterRequest: AccountRegisterRequest, options?: any) {
        return AccountApiFp(this.configuration).accountsRegisterPost(accountRegisterRequest, options)(this.axios, this.basePath);
    }

}


/**
 * AlertApi - axios parameter creator
 * @export
 */
export const AlertApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary アラート一覧情報を取得する
         * @param {number} [page] page
         * @param {number} [limit] limit
         * @param {number} [organizationId] 組織id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminAlertsGet(page?: number, limit?: number, organizationId?: number, options: any = {}): RequestArgs {
            const localVarPath = `/admin/alerts`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (organizationId !== undefined) {
                localVarQueryParameter['organizationId'] = organizationId;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AlertApi - functional programming interface
 * @export
 */
export const AlertApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary アラート一覧情報を取得する
         * @param {number} [page] page
         * @param {number} [limit] limit
         * @param {number} [organizationId] 組織id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminAlertsGet(page?: number, limit?: number, organizationId?: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<AlertsResponse> {
            const localVarAxiosArgs = AlertApiAxiosParamCreator(configuration).adminAlertsGet(page, limit, organizationId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * AlertApi - factory interface
 * @export
 */
export const AlertApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @summary アラート一覧情報を取得する
         * @param {number} [page] page
         * @param {number} [limit] limit
         * @param {number} [organizationId] 組織id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminAlertsGet(page?: number, limit?: number, organizationId?: number, options?: any) {
            return AlertApiFp(configuration).adminAlertsGet(page, limit, organizationId, options)(axios, basePath);
        },
    };
};

/**
 * AlertApi - object-oriented interface
 * @export
 * @class AlertApi
 * @extends {BaseAPI}
 */
export class AlertApi extends BaseAPI {
    /**
     * 
     * @summary アラート一覧情報を取得する
     * @param {number} [page] page
     * @param {number} [limit] limit
     * @param {number} [organizationId] 組織id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AlertApi
     */
    public adminAlertsGet(page?: number, limit?: number, organizationId?: number, options?: any) {
        return AlertApiFp(this.configuration).adminAlertsGet(page, limit, organizationId, options)(this.axios, this.basePath);
    }

}


/**
 * ContractApi - axios parameter creator
 * @export
 */
export const ContractApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary 今期の契約情報を取得する
         * @param {number} organizationId 組織ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        organizationsOrganizationIdContractInfoGet(organizationId: number, options: any = {}): RequestArgs {
            // verify required parameter 'organizationId' is not null or undefined
            if (organizationId === null || organizationId === undefined) {
                throw new RequiredError('organizationId','Required parameter organizationId was null or undefined when calling organizationsOrganizationIdContractInfoGet.');
            }
            const localVarPath = `/organizations/{organization_id}/contract_info/`
                .replace(`{${"organization_id"}}`, encodeURIComponent(String(organizationId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ContractApi - functional programming interface
 * @export
 */
export const ContractApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary 今期の契約情報を取得する
         * @param {number} organizationId 組織ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        organizationsOrganizationIdContractInfoGet(organizationId: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ContractResponse> {
            const localVarAxiosArgs = ContractApiAxiosParamCreator(configuration).organizationsOrganizationIdContractInfoGet(organizationId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * ContractApi - factory interface
 * @export
 */
export const ContractApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @summary 今期の契約情報を取得する
         * @param {number} organizationId 組織ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        organizationsOrganizationIdContractInfoGet(organizationId: number, options?: any) {
            return ContractApiFp(configuration).organizationsOrganizationIdContractInfoGet(organizationId, options)(axios, basePath);
        },
    };
};

/**
 * ContractApi - object-oriented interface
 * @export
 * @class ContractApi
 * @extends {BaseAPI}
 */
export class ContractApi extends BaseAPI {
    /**
     * 
     * @summary 今期の契約情報を取得する
     * @param {number} organizationId 組織ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContractApi
     */
    public organizationsOrganizationIdContractInfoGet(organizationId: number, options?: any) {
        return ContractApiFp(this.configuration).organizationsOrganizationIdContractInfoGet(organizationId, options)(this.axios, this.basePath);
    }

}


/**
 * CounselingApi - axios parameter creator
 * @export
 */
export const CounselingApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary 面談調整を一括作成する
         * @param {BulkCreateCounselingRequest} bulkCreateCounselingRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminCounselingsBulkCreatePost(bulkCreateCounselingRequest: BulkCreateCounselingRequest, options: any = {}): RequestArgs {
            // verify required parameter 'bulkCreateCounselingRequest' is not null or undefined
            if (bulkCreateCounselingRequest === null || bulkCreateCounselingRequest === undefined) {
                throw new RequiredError('bulkCreateCounselingRequest','Required parameter bulkCreateCounselingRequest was null or undefined when calling adminCounselingsBulkCreatePost.');
            }
            const localVarPath = `/admin/counselings/bulk-create`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (<any>"BulkCreateCounselingRequest" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(bulkCreateCounselingRequest !== undefined ? bulkCreateCounselingRequest : {}) : (bulkCreateCounselingRequest || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 任意のカウンセラーが受け持っている面談記録を取得する
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminCounselingsGet(options: any = {}): RequestArgs {
            const localVarPath = `/admin/counselings/`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 面談記録を取得する
         * @param {number} reservationId 面談予約ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminCounselingsReservationIdGet(reservationId: number, options: any = {}): RequestArgs {
            // verify required parameter 'reservationId' is not null or undefined
            if (reservationId === null || reservationId === undefined) {
                throw new RequiredError('reservationId','Required parameter reservationId was null or undefined when calling adminCounselingsReservationIdGet.');
            }
            const localVarPath = `/admin/counselings/{reservation_id}`
                .replace(`{${"reservation_id"}}`, encodeURIComponent(String(reservationId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 面談記録を更新する
         * @param {number} reservationId 面談予約ID
         * @param {UpdateCounselingRequest} updateCounselingRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminCounselingsReservationIdPut(reservationId: number, updateCounselingRequest: UpdateCounselingRequest, options: any = {}): RequestArgs {
            // verify required parameter 'reservationId' is not null or undefined
            if (reservationId === null || reservationId === undefined) {
                throw new RequiredError('reservationId','Required parameter reservationId was null or undefined when calling adminCounselingsReservationIdPut.');
            }
            // verify required parameter 'updateCounselingRequest' is not null or undefined
            if (updateCounselingRequest === null || updateCounselingRequest === undefined) {
                throw new RequiredError('updateCounselingRequest','Required parameter updateCounselingRequest was null or undefined when calling adminCounselingsReservationIdPut.');
            }
            const localVarPath = `/admin/counselings/{reservation_id}`
                .replace(`{${"reservation_id"}}`, encodeURIComponent(String(reservationId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (<any>"UpdateCounselingRequest" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(updateCounselingRequest !== undefined ? updateCounselingRequest : {}) : (updateCounselingRequest || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 面談記録を更新する
         * @param {number} reservationId 面談予約ID
         * @param {UpdateCounselingReservationRequest} updateCounselingReservationRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminCounselingsReservationReservationIdPut(reservationId: number, updateCounselingReservationRequest: UpdateCounselingReservationRequest, options: any = {}): RequestArgs {
            // verify required parameter 'reservationId' is not null or undefined
            if (reservationId === null || reservationId === undefined) {
                throw new RequiredError('reservationId','Required parameter reservationId was null or undefined when calling adminCounselingsReservationReservationIdPut.');
            }
            // verify required parameter 'updateCounselingReservationRequest' is not null or undefined
            if (updateCounselingReservationRequest === null || updateCounselingReservationRequest === undefined) {
                throw new RequiredError('updateCounselingReservationRequest','Required parameter updateCounselingReservationRequest was null or undefined when calling adminCounselingsReservationReservationIdPut.');
            }
            const localVarPath = `/admin/counselings/reservation/{reservation_id}`
                .replace(`{${"reservation_id"}}`, encodeURIComponent(String(reservationId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (<any>"UpdateCounselingReservationRequest" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(updateCounselingReservationRequest !== undefined ? updateCounselingReservationRequest : {}) : (updateCounselingReservationRequest || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 任意のカウンセラーが受け持っている面談記録を取得する
         * @param {number} [counselorId] カウンセラーID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminCounselingsReservationsGet(counselorId?: number, options: any = {}): RequestArgs {
            const localVarPath = `/admin/counselings/reservations/`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (counselorId !== undefined) {
                localVarQueryParameter['counselorId'] = counselorId;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary カウンセラー一覧取得
         * @param {number} [organizationId] 組織ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminCounselorsGet(organizationId?: number, options: any = {}): RequestArgs {
            const localVarPath = `/admin/counselors/`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (organizationId !== undefined) {
                localVarQueryParameter['organizationId'] = organizationId;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 企業-カウンセラーの紐付け更新
         * @param {number} organizationId 組織ID
         * @param {Array<number>} [counselorIds] カウンセラーID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminCounselorsOrganizationRelationPost(organizationId: number, counselorIds?: Array<number>, options: any = {}): RequestArgs {
            // verify required parameter 'organizationId' is not null or undefined
            if (organizationId === null || organizationId === undefined) {
                throw new RequiredError('organizationId','Required parameter organizationId was null or undefined when calling adminCounselorsOrganizationRelationPost.');
            }
            const localVarPath = `/admin/counselors/organization-relation/`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (organizationId !== undefined) {
                localVarQueryParameter['organizationId'] = organizationId;
            }

            if (counselorIds) {
                localVarQueryParameter['counselorIds'] = counselorIds;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 面談フォームのパラメータ
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        counselingsFormGet(options: any = {}): RequestArgs {
            const localVarPath = `/counselings/form`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 自分が受け持つ面談記録を取得する
         * @param {number} [employeeId] 従業員ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        counselorCounselingsGet(employeeId?: number, options: any = {}): RequestArgs {
            const localVarPath = `/counselor/counselings/`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (employeeId !== undefined) {
                localVarQueryParameter['employeeId'] = employeeId;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 面談記録を取得する
         * @param {number} reservationId 面談予約ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        counselorCounselingsReservationIdGet(reservationId: number, options: any = {}): RequestArgs {
            // verify required parameter 'reservationId' is not null or undefined
            if (reservationId === null || reservationId === undefined) {
                throw new RequiredError('reservationId','Required parameter reservationId was null or undefined when calling counselorCounselingsReservationIdGet.');
            }
            const localVarPath = `/counselor/counselings/{reservation_id}`
                .replace(`{${"reservation_id"}}`, encodeURIComponent(String(reservationId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 面談記録を更新する
         * @param {number} reservationId 面談予約ID
         * @param {UpdateCounselingRequest} updateCounselingRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        counselorCounselingsReservationIdPut(reservationId: number, updateCounselingRequest: UpdateCounselingRequest, options: any = {}): RequestArgs {
            // verify required parameter 'reservationId' is not null or undefined
            if (reservationId === null || reservationId === undefined) {
                throw new RequiredError('reservationId','Required parameter reservationId was null or undefined when calling counselorCounselingsReservationIdPut.');
            }
            // verify required parameter 'updateCounselingRequest' is not null or undefined
            if (updateCounselingRequest === null || updateCounselingRequest === undefined) {
                throw new RequiredError('updateCounselingRequest','Required parameter updateCounselingRequest was null or undefined when calling counselorCounselingsReservationIdPut.');
            }
            const localVarPath = `/counselor/counselings/{reservation_id}`
                .replace(`{${"reservation_id"}}`, encodeURIComponent(String(reservationId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (<any>"UpdateCounselingRequest" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(updateCounselingRequest !== undefined ? updateCounselingRequest : {}) : (updateCounselingRequest || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CounselingApi - functional programming interface
 * @export
 */
export const CounselingApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary 面談調整を一括作成する
         * @param {BulkCreateCounselingRequest} bulkCreateCounselingRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminCounselingsBulkCreatePost(bulkCreateCounselingRequest: BulkCreateCounselingRequest, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
            const localVarAxiosArgs = CounselingApiAxiosParamCreator(configuration).adminCounselingsBulkCreatePost(bulkCreateCounselingRequest, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary 任意のカウンセラーが受け持っている面談記録を取得する
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminCounselingsGet(options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CounselingResponse>> {
            const localVarAxiosArgs = CounselingApiAxiosParamCreator(configuration).adminCounselingsGet(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary 面談記録を取得する
         * @param {number} reservationId 面談予約ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminCounselingsReservationIdGet(reservationId: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<CounselingDetailResponse> {
            const localVarAxiosArgs = CounselingApiAxiosParamCreator(configuration).adminCounselingsReservationIdGet(reservationId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary 面談記録を更新する
         * @param {number} reservationId 面談予約ID
         * @param {UpdateCounselingRequest} updateCounselingRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminCounselingsReservationIdPut(reservationId: number, updateCounselingRequest: UpdateCounselingRequest, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
            const localVarAxiosArgs = CounselingApiAxiosParamCreator(configuration).adminCounselingsReservationIdPut(reservationId, updateCounselingRequest, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary 面談記録を更新する
         * @param {number} reservationId 面談予約ID
         * @param {UpdateCounselingReservationRequest} updateCounselingReservationRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminCounselingsReservationReservationIdPut(reservationId: number, updateCounselingReservationRequest: UpdateCounselingReservationRequest, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
            const localVarAxiosArgs = CounselingApiAxiosParamCreator(configuration).adminCounselingsReservationReservationIdPut(reservationId, updateCounselingReservationRequest, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary 任意のカウンセラーが受け持っている面談記録を取得する
         * @param {number} [counselorId] カウンセラーID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminCounselingsReservationsGet(counselorId?: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CounselingResponse>> {
            const localVarAxiosArgs = CounselingApiAxiosParamCreator(configuration).adminCounselingsReservationsGet(counselorId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary カウンセラー一覧取得
         * @param {number} [organizationId] 組織ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminCounselorsGet(organizationId?: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CounselorResponse>> {
            const localVarAxiosArgs = CounselingApiAxiosParamCreator(configuration).adminCounselorsGet(organizationId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary 企業-カウンセラーの紐付け更新
         * @param {number} organizationId 組織ID
         * @param {Array<number>} [counselorIds] カウンセラーID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminCounselorsOrganizationRelationPost(organizationId: number, counselorIds?: Array<number>, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
            const localVarAxiosArgs = CounselingApiAxiosParamCreator(configuration).adminCounselorsOrganizationRelationPost(organizationId, counselorIds, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary 面談フォームのパラメータ
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        counselingsFormGet(options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<CounselingFormResponse> {
            const localVarAxiosArgs = CounselingApiAxiosParamCreator(configuration).counselingsFormGet(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary 自分が受け持つ面談記録を取得する
         * @param {number} [employeeId] 従業員ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        counselorCounselingsGet(employeeId?: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CounselingResponse>> {
            const localVarAxiosArgs = CounselingApiAxiosParamCreator(configuration).counselorCounselingsGet(employeeId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary 面談記録を取得する
         * @param {number} reservationId 面談予約ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        counselorCounselingsReservationIdGet(reservationId: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<CounselingDetailResponse> {
            const localVarAxiosArgs = CounselingApiAxiosParamCreator(configuration).counselorCounselingsReservationIdGet(reservationId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary 面談記録を更新する
         * @param {number} reservationId 面談予約ID
         * @param {UpdateCounselingRequest} updateCounselingRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        counselorCounselingsReservationIdPut(reservationId: number, updateCounselingRequest: UpdateCounselingRequest, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
            const localVarAxiosArgs = CounselingApiAxiosParamCreator(configuration).counselorCounselingsReservationIdPut(reservationId, updateCounselingRequest, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * CounselingApi - factory interface
 * @export
 */
export const CounselingApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @summary 面談調整を一括作成する
         * @param {BulkCreateCounselingRequest} bulkCreateCounselingRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminCounselingsBulkCreatePost(bulkCreateCounselingRequest: BulkCreateCounselingRequest, options?: any) {
            return CounselingApiFp(configuration).adminCounselingsBulkCreatePost(bulkCreateCounselingRequest, options)(axios, basePath);
        },
        /**
         * 
         * @summary 任意のカウンセラーが受け持っている面談記録を取得する
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminCounselingsGet(options?: any) {
            return CounselingApiFp(configuration).adminCounselingsGet(options)(axios, basePath);
        },
        /**
         * 
         * @summary 面談記録を取得する
         * @param {number} reservationId 面談予約ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminCounselingsReservationIdGet(reservationId: number, options?: any) {
            return CounselingApiFp(configuration).adminCounselingsReservationIdGet(reservationId, options)(axios, basePath);
        },
        /**
         * 
         * @summary 面談記録を更新する
         * @param {number} reservationId 面談予約ID
         * @param {UpdateCounselingRequest} updateCounselingRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminCounselingsReservationIdPut(reservationId: number, updateCounselingRequest: UpdateCounselingRequest, options?: any) {
            return CounselingApiFp(configuration).adminCounselingsReservationIdPut(reservationId, updateCounselingRequest, options)(axios, basePath);
        },
        /**
         * 
         * @summary 面談記録を更新する
         * @param {number} reservationId 面談予約ID
         * @param {UpdateCounselingReservationRequest} updateCounselingReservationRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminCounselingsReservationReservationIdPut(reservationId: number, updateCounselingReservationRequest: UpdateCounselingReservationRequest, options?: any) {
            return CounselingApiFp(configuration).adminCounselingsReservationReservationIdPut(reservationId, updateCounselingReservationRequest, options)(axios, basePath);
        },
        /**
         * 
         * @summary 任意のカウンセラーが受け持っている面談記録を取得する
         * @param {number} [counselorId] カウンセラーID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminCounselingsReservationsGet(counselorId?: number, options?: any) {
            return CounselingApiFp(configuration).adminCounselingsReservationsGet(counselorId, options)(axios, basePath);
        },
        /**
         * 
         * @summary カウンセラー一覧取得
         * @param {number} [organizationId] 組織ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminCounselorsGet(organizationId?: number, options?: any) {
            return CounselingApiFp(configuration).adminCounselorsGet(organizationId, options)(axios, basePath);
        },
        /**
         * 
         * @summary 企業-カウンセラーの紐付け更新
         * @param {number} organizationId 組織ID
         * @param {Array<number>} [counselorIds] カウンセラーID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminCounselorsOrganizationRelationPost(organizationId: number, counselorIds?: Array<number>, options?: any) {
            return CounselingApiFp(configuration).adminCounselorsOrganizationRelationPost(organizationId, counselorIds, options)(axios, basePath);
        },
        /**
         * 
         * @summary 面談フォームのパラメータ
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        counselingsFormGet(options?: any) {
            return CounselingApiFp(configuration).counselingsFormGet(options)(axios, basePath);
        },
        /**
         * 
         * @summary 自分が受け持つ面談記録を取得する
         * @param {number} [employeeId] 従業員ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        counselorCounselingsGet(employeeId?: number, options?: any) {
            return CounselingApiFp(configuration).counselorCounselingsGet(employeeId, options)(axios, basePath);
        },
        /**
         * 
         * @summary 面談記録を取得する
         * @param {number} reservationId 面談予約ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        counselorCounselingsReservationIdGet(reservationId: number, options?: any) {
            return CounselingApiFp(configuration).counselorCounselingsReservationIdGet(reservationId, options)(axios, basePath);
        },
        /**
         * 
         * @summary 面談記録を更新する
         * @param {number} reservationId 面談予約ID
         * @param {UpdateCounselingRequest} updateCounselingRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        counselorCounselingsReservationIdPut(reservationId: number, updateCounselingRequest: UpdateCounselingRequest, options?: any) {
            return CounselingApiFp(configuration).counselorCounselingsReservationIdPut(reservationId, updateCounselingRequest, options)(axios, basePath);
        },
    };
};

/**
 * CounselingApi - object-oriented interface
 * @export
 * @class CounselingApi
 * @extends {BaseAPI}
 */
export class CounselingApi extends BaseAPI {
    /**
     * 
     * @summary 面談調整を一括作成する
     * @param {BulkCreateCounselingRequest} bulkCreateCounselingRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CounselingApi
     */
    public adminCounselingsBulkCreatePost(bulkCreateCounselingRequest: BulkCreateCounselingRequest, options?: any) {
        return CounselingApiFp(this.configuration).adminCounselingsBulkCreatePost(bulkCreateCounselingRequest, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary 任意のカウンセラーが受け持っている面談記録を取得する
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CounselingApi
     */
    public adminCounselingsGet(options?: any) {
        return CounselingApiFp(this.configuration).adminCounselingsGet(options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary 面談記録を取得する
     * @param {number} reservationId 面談予約ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CounselingApi
     */
    public adminCounselingsReservationIdGet(reservationId: number, options?: any) {
        return CounselingApiFp(this.configuration).adminCounselingsReservationIdGet(reservationId, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary 面談記録を更新する
     * @param {number} reservationId 面談予約ID
     * @param {UpdateCounselingRequest} updateCounselingRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CounselingApi
     */
    public adminCounselingsReservationIdPut(reservationId: number, updateCounselingRequest: UpdateCounselingRequest, options?: any) {
        return CounselingApiFp(this.configuration).adminCounselingsReservationIdPut(reservationId, updateCounselingRequest, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary 面談記録を更新する
     * @param {number} reservationId 面談予約ID
     * @param {UpdateCounselingReservationRequest} updateCounselingReservationRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CounselingApi
     */
    public adminCounselingsReservationReservationIdPut(reservationId: number, updateCounselingReservationRequest: UpdateCounselingReservationRequest, options?: any) {
        return CounselingApiFp(this.configuration).adminCounselingsReservationReservationIdPut(reservationId, updateCounselingReservationRequest, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary 任意のカウンセラーが受け持っている面談記録を取得する
     * @param {number} [counselorId] カウンセラーID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CounselingApi
     */
    public adminCounselingsReservationsGet(counselorId?: number, options?: any) {
        return CounselingApiFp(this.configuration).adminCounselingsReservationsGet(counselorId, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary カウンセラー一覧取得
     * @param {number} [organizationId] 組織ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CounselingApi
     */
    public adminCounselorsGet(organizationId?: number, options?: any) {
        return CounselingApiFp(this.configuration).adminCounselorsGet(organizationId, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary 企業-カウンセラーの紐付け更新
     * @param {number} organizationId 組織ID
     * @param {Array<number>} [counselorIds] カウンセラーID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CounselingApi
     */
    public adminCounselorsOrganizationRelationPost(organizationId: number, counselorIds?: Array<number>, options?: any) {
        return CounselingApiFp(this.configuration).adminCounselorsOrganizationRelationPost(organizationId, counselorIds, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary 面談フォームのパラメータ
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CounselingApi
     */
    public counselingsFormGet(options?: any) {
        return CounselingApiFp(this.configuration).counselingsFormGet(options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary 自分が受け持つ面談記録を取得する
     * @param {number} [employeeId] 従業員ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CounselingApi
     */
    public counselorCounselingsGet(employeeId?: number, options?: any) {
        return CounselingApiFp(this.configuration).counselorCounselingsGet(employeeId, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary 面談記録を取得する
     * @param {number} reservationId 面談予約ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CounselingApi
     */
    public counselorCounselingsReservationIdGet(reservationId: number, options?: any) {
        return CounselingApiFp(this.configuration).counselorCounselingsReservationIdGet(reservationId, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary 面談記録を更新する
     * @param {number} reservationId 面談予約ID
     * @param {UpdateCounselingRequest} updateCounselingRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CounselingApi
     */
    public counselorCounselingsReservationIdPut(reservationId: number, updateCounselingRequest: UpdateCounselingRequest, options?: any) {
        return CounselingApiFp(this.configuration).counselorCounselingsReservationIdPut(reservationId, updateCounselingRequest, options)(this.axios, this.basePath);
    }

}


/**
 * EmployeeApi - axios parameter creator
 * @export
 */
export const EmployeeApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary 従業員情報を取得する
         * @param {number} [page] page
         * @param {number} [limit] limit
         * @param {number} [organizationId] 組織id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminEmployeesGet(page?: number, limit?: number, organizationId?: number, options: any = {}): RequestArgs {
            const localVarPath = `/admin/employees`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (organizationId !== undefined) {
                localVarQueryParameter['organizationId'] = organizationId;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary アンケート結果を取得する
         * @param {number} id 従業員ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        counselorEmployeeIdSurveyResultsGet(id: number, options: any = {}): RequestArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling counselorEmployeeIdSurveyResultsGet.');
            }
            const localVarPath = `/counselor/employee/{id}/survey-results`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * EmployeeApi - functional programming interface
 * @export
 */
export const EmployeeApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary 従業員情報を取得する
         * @param {number} [page] page
         * @param {number} [limit] limit
         * @param {number} [organizationId] 組織id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminEmployeesGet(page?: number, limit?: number, organizationId?: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<EmployeesResponse> {
            const localVarAxiosArgs = EmployeeApiAxiosParamCreator(configuration).adminEmployeesGet(page, limit, organizationId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary アンケート結果を取得する
         * @param {number} id 従業員ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        counselorEmployeeIdSurveyResultsGet(id: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<SurveyResultsResponse> {
            const localVarAxiosArgs = EmployeeApiAxiosParamCreator(configuration).counselorEmployeeIdSurveyResultsGet(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * EmployeeApi - factory interface
 * @export
 */
export const EmployeeApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @summary 従業員情報を取得する
         * @param {number} [page] page
         * @param {number} [limit] limit
         * @param {number} [organizationId] 組織id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminEmployeesGet(page?: number, limit?: number, organizationId?: number, options?: any) {
            return EmployeeApiFp(configuration).adminEmployeesGet(page, limit, organizationId, options)(axios, basePath);
        },
        /**
         * 
         * @summary アンケート結果を取得する
         * @param {number} id 従業員ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        counselorEmployeeIdSurveyResultsGet(id: number, options?: any) {
            return EmployeeApiFp(configuration).counselorEmployeeIdSurveyResultsGet(id, options)(axios, basePath);
        },
    };
};

/**
 * EmployeeApi - object-oriented interface
 * @export
 * @class EmployeeApi
 * @extends {BaseAPI}
 */
export class EmployeeApi extends BaseAPI {
    /**
     * 
     * @summary 従業員情報を取得する
     * @param {number} [page] page
     * @param {number} [limit] limit
     * @param {number} [organizationId] 組織id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmployeeApi
     */
    public adminEmployeesGet(page?: number, limit?: number, organizationId?: number, options?: any) {
        return EmployeeApiFp(this.configuration).adminEmployeesGet(page, limit, organizationId, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary アンケート結果を取得する
     * @param {number} id 従業員ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmployeeApi
     */
    public counselorEmployeeIdSurveyResultsGet(id: number, options?: any) {
        return EmployeeApiFp(this.configuration).counselorEmployeeIdSurveyResultsGet(id, options)(this.axios, this.basePath);
    }

}


/**
 * OrganizationApi - axios parameter creator
 * @export
 */
export const OrganizationApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary 組織の一覧を取得する
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminOrganizationsGet(options: any = {}): RequestArgs {
            const localVarPath = `/admin/organizations`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * OrganizationApi - functional programming interface
 * @export
 */
export const OrganizationApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary 組織の一覧を取得する
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminOrganizationsGet(options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<OrganizationResponse>> {
            const localVarAxiosArgs = OrganizationApiAxiosParamCreator(configuration).adminOrganizationsGet(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * OrganizationApi - factory interface
 * @export
 */
export const OrganizationApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @summary 組織の一覧を取得する
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminOrganizationsGet(options?: any) {
            return OrganizationApiFp(configuration).adminOrganizationsGet(options)(axios, basePath);
        },
    };
};

/**
 * OrganizationApi - object-oriented interface
 * @export
 * @class OrganizationApi
 * @extends {BaseAPI}
 */
export class OrganizationApi extends BaseAPI {
    /**
     * 
     * @summary 組織の一覧を取得する
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationApi
     */
    public adminOrganizationsGet(options?: any) {
        return OrganizationApiFp(this.configuration).adminOrganizationsGet(options)(this.axios, this.basePath);
    }

}


/**
 * ScheduleFrameApi - axios parameter creator
 * @export
 */
export const ScheduleFrameApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary スケジュール枠の一覧取得
         * @param {number} counselorId カウンセラーID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        counselorCounselorIdScheduleFramesGet(counselorId: number, options: any = {}): RequestArgs {
            // verify required parameter 'counselorId' is not null or undefined
            if (counselorId === null || counselorId === undefined) {
                throw new RequiredError('counselorId','Required parameter counselorId was null or undefined when calling counselorCounselorIdScheduleFramesGet.');
            }
            const localVarPath = `/counselor/{counselorId}/schedule-frames`
                .replace(`{${"counselorId"}}`, encodeURIComponent(String(counselorId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary スケジュール枠の作成
         * @param {number} counselorId カウンセラーID
         * @param {ScheduleFramesRequest} scheduleFramesRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        counselorCounselorIdScheduleFramesPost(counselorId: number, scheduleFramesRequest: ScheduleFramesRequest, options: any = {}): RequestArgs {
            // verify required parameter 'counselorId' is not null or undefined
            if (counselorId === null || counselorId === undefined) {
                throw new RequiredError('counselorId','Required parameter counselorId was null or undefined when calling counselorCounselorIdScheduleFramesPost.');
            }
            // verify required parameter 'scheduleFramesRequest' is not null or undefined
            if (scheduleFramesRequest === null || scheduleFramesRequest === undefined) {
                throw new RequiredError('scheduleFramesRequest','Required parameter scheduleFramesRequest was null or undefined when calling counselorCounselorIdScheduleFramesPost.');
            }
            const localVarPath = `/counselor/{counselorId}/schedule-frames`
                .replace(`{${"counselorId"}}`, encodeURIComponent(String(counselorId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (<any>"ScheduleFramesRequest" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(scheduleFramesRequest !== undefined ? scheduleFramesRequest : {}) : (scheduleFramesRequest || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary スケジュール枠の削除
         * @param {number} scheduleFrameId 予約枠ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        counselorScheduleFramesScheduleFrameIdDelete(scheduleFrameId: number, options: any = {}): RequestArgs {
            // verify required parameter 'scheduleFrameId' is not null or undefined
            if (scheduleFrameId === null || scheduleFrameId === undefined) {
                throw new RequiredError('scheduleFrameId','Required parameter scheduleFrameId was null or undefined when calling counselorScheduleFramesScheduleFrameIdDelete.');
            }
            const localVarPath = `/counselor/schedule-frames/{scheduleFrameId}`
                .replace(`{${"scheduleFrameId"}}`, encodeURIComponent(String(scheduleFrameId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ScheduleFrameApi - functional programming interface
 * @export
 */
export const ScheduleFrameApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary スケジュール枠の一覧取得
         * @param {number} counselorId カウンセラーID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        counselorCounselorIdScheduleFramesGet(counselorId: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ScheduleFramesResponse> {
            const localVarAxiosArgs = ScheduleFrameApiAxiosParamCreator(configuration).counselorCounselorIdScheduleFramesGet(counselorId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary スケジュール枠の作成
         * @param {number} counselorId カウンセラーID
         * @param {ScheduleFramesRequest} scheduleFramesRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        counselorCounselorIdScheduleFramesPost(counselorId: number, scheduleFramesRequest: ScheduleFramesRequest, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
            const localVarAxiosArgs = ScheduleFrameApiAxiosParamCreator(configuration).counselorCounselorIdScheduleFramesPost(counselorId, scheduleFramesRequest, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary スケジュール枠の削除
         * @param {number} scheduleFrameId 予約枠ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        counselorScheduleFramesScheduleFrameIdDelete(scheduleFrameId: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
            const localVarAxiosArgs = ScheduleFrameApiAxiosParamCreator(configuration).counselorScheduleFramesScheduleFrameIdDelete(scheduleFrameId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * ScheduleFrameApi - factory interface
 * @export
 */
export const ScheduleFrameApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @summary スケジュール枠の一覧取得
         * @param {number} counselorId カウンセラーID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        counselorCounselorIdScheduleFramesGet(counselorId: number, options?: any) {
            return ScheduleFrameApiFp(configuration).counselorCounselorIdScheduleFramesGet(counselorId, options)(axios, basePath);
        },
        /**
         * 
         * @summary スケジュール枠の作成
         * @param {number} counselorId カウンセラーID
         * @param {ScheduleFramesRequest} scheduleFramesRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        counselorCounselorIdScheduleFramesPost(counselorId: number, scheduleFramesRequest: ScheduleFramesRequest, options?: any) {
            return ScheduleFrameApiFp(configuration).counselorCounselorIdScheduleFramesPost(counselorId, scheduleFramesRequest, options)(axios, basePath);
        },
        /**
         * 
         * @summary スケジュール枠の削除
         * @param {number} scheduleFrameId 予約枠ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        counselorScheduleFramesScheduleFrameIdDelete(scheduleFrameId: number, options?: any) {
            return ScheduleFrameApiFp(configuration).counselorScheduleFramesScheduleFrameIdDelete(scheduleFrameId, options)(axios, basePath);
        },
    };
};

/**
 * ScheduleFrameApi - object-oriented interface
 * @export
 * @class ScheduleFrameApi
 * @extends {BaseAPI}
 */
export class ScheduleFrameApi extends BaseAPI {
    /**
     * 
     * @summary スケジュール枠の一覧取得
     * @param {number} counselorId カウンセラーID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ScheduleFrameApi
     */
    public counselorCounselorIdScheduleFramesGet(counselorId: number, options?: any) {
        return ScheduleFrameApiFp(this.configuration).counselorCounselorIdScheduleFramesGet(counselorId, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary スケジュール枠の作成
     * @param {number} counselorId カウンセラーID
     * @param {ScheduleFramesRequest} scheduleFramesRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ScheduleFrameApi
     */
    public counselorCounselorIdScheduleFramesPost(counselorId: number, scheduleFramesRequest: ScheduleFramesRequest, options?: any) {
        return ScheduleFrameApiFp(this.configuration).counselorCounselorIdScheduleFramesPost(counselorId, scheduleFramesRequest, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary スケジュール枠の削除
     * @param {number} scheduleFrameId 予約枠ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ScheduleFrameApi
     */
    public counselorScheduleFramesScheduleFrameIdDelete(scheduleFrameId: number, options?: any) {
        return ScheduleFrameApiFp(this.configuration).counselorScheduleFramesScheduleFrameIdDelete(scheduleFrameId, options)(this.axios, this.basePath);
    }

}


/**
 * SurveyDeliveryApi - axios parameter creator
 * @export
 */
export const SurveyDeliveryApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary すべてのアンケート配信を取得する
         * @param {number} [organizationId] 組織ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminSurveyDeliveriesGet(organizationId?: number, options: any = {}): RequestArgs {
            const localVarPath = `/admin/survey-deliveries`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (organizationId !== undefined) {
                localVarQueryParameter['organizationId'] = organizationId;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SurveyDeliveryApi - functional programming interface
 * @export
 */
export const SurveyDeliveryApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary すべてのアンケート配信を取得する
         * @param {number} [organizationId] 組織ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminSurveyDeliveriesGet(organizationId?: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<SurveyDeliveryResponse>> {
            const localVarAxiosArgs = SurveyDeliveryApiAxiosParamCreator(configuration).adminSurveyDeliveriesGet(organizationId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * SurveyDeliveryApi - factory interface
 * @export
 */
export const SurveyDeliveryApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @summary すべてのアンケート配信を取得する
         * @param {number} [organizationId] 組織ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminSurveyDeliveriesGet(organizationId?: number, options?: any) {
            return SurveyDeliveryApiFp(configuration).adminSurveyDeliveriesGet(organizationId, options)(axios, basePath);
        },
    };
};

/**
 * SurveyDeliveryApi - object-oriented interface
 * @export
 * @class SurveyDeliveryApi
 * @extends {BaseAPI}
 */
export class SurveyDeliveryApi extends BaseAPI {
    /**
     * 
     * @summary すべてのアンケート配信を取得する
     * @param {number} [organizationId] 組織ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SurveyDeliveryApi
     */
    public adminSurveyDeliveriesGet(organizationId?: number, options?: any) {
        return SurveyDeliveryApiFp(this.configuration).adminSurveyDeliveriesGet(organizationId, options)(this.axios, this.basePath);
    }

}


/**
 * SurveyResultApi - axios parameter creator
 * @export
 */
export const SurveyResultApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary アンケート結果を取得する
         * @param {number} [employeeId] 従業員ID
         * @param {number} [organizationId] 組織ID
         * @param {number} [page] page
         * @param {number} [limit] limit
         * @param {boolean} [orderByAlert] アラート順にソート
         * @param {number} [surveyDeliveryId] アンケート配信ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminSurveyResultsGet(employeeId?: number, organizationId?: number, page?: number, limit?: number, orderByAlert?: boolean, surveyDeliveryId?: number, options: any = {}): RequestArgs {
            const localVarPath = `/admin/survey-results`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (employeeId !== undefined) {
                localVarQueryParameter['employeeId'] = employeeId;
            }

            if (organizationId !== undefined) {
                localVarQueryParameter['organizationId'] = organizationId;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (orderByAlert !== undefined) {
                localVarQueryParameter['orderByAlert'] = orderByAlert;
            }

            if (surveyDeliveryId !== undefined) {
                localVarQueryParameter['surveyDeliveryId'] = surveyDeliveryId;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary アンケート結果詳細を取得する
         * @param {number} id アンケート結果ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminSurveyResultsIdGet(id: number, options: any = {}): RequestArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling adminSurveyResultsIdGet.');
            }
            const localVarPath = `/admin/survey-results/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary アンケート結果詳細を取得する
         * @param {number} id アンケート結果ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        counselorSurveyResultsIdGet(id: number, options: any = {}): RequestArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling counselorSurveyResultsIdGet.');
            }
            const localVarPath = `/counselor/survey-results/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SurveyResultApi - functional programming interface
 * @export
 */
export const SurveyResultApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary アンケート結果を取得する
         * @param {number} [employeeId] 従業員ID
         * @param {number} [organizationId] 組織ID
         * @param {number} [page] page
         * @param {number} [limit] limit
         * @param {boolean} [orderByAlert] アラート順にソート
         * @param {number} [surveyDeliveryId] アンケート配信ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminSurveyResultsGet(employeeId?: number, organizationId?: number, page?: number, limit?: number, orderByAlert?: boolean, surveyDeliveryId?: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginatedSurveyResultsResponse> {
            const localVarAxiosArgs = SurveyResultApiAxiosParamCreator(configuration).adminSurveyResultsGet(employeeId, organizationId, page, limit, orderByAlert, surveyDeliveryId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary アンケート結果詳細を取得する
         * @param {number} id アンケート結果ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminSurveyResultsIdGet(id: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<SurveyResultDetailResponse> {
            const localVarAxiosArgs = SurveyResultApiAxiosParamCreator(configuration).adminSurveyResultsIdGet(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary アンケート結果詳細を取得する
         * @param {number} id アンケート結果ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        counselorSurveyResultsIdGet(id: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<SurveyResultDetailResponse> {
            const localVarAxiosArgs = SurveyResultApiAxiosParamCreator(configuration).counselorSurveyResultsIdGet(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * SurveyResultApi - factory interface
 * @export
 */
export const SurveyResultApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @summary アンケート結果を取得する
         * @param {number} [employeeId] 従業員ID
         * @param {number} [organizationId] 組織ID
         * @param {number} [page] page
         * @param {number} [limit] limit
         * @param {boolean} [orderByAlert] アラート順にソート
         * @param {number} [surveyDeliveryId] アンケート配信ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminSurveyResultsGet(employeeId?: number, organizationId?: number, page?: number, limit?: number, orderByAlert?: boolean, surveyDeliveryId?: number, options?: any) {
            return SurveyResultApiFp(configuration).adminSurveyResultsGet(employeeId, organizationId, page, limit, orderByAlert, surveyDeliveryId, options)(axios, basePath);
        },
        /**
         * 
         * @summary アンケート結果詳細を取得する
         * @param {number} id アンケート結果ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminSurveyResultsIdGet(id: number, options?: any) {
            return SurveyResultApiFp(configuration).adminSurveyResultsIdGet(id, options)(axios, basePath);
        },
        /**
         * 
         * @summary アンケート結果詳細を取得する
         * @param {number} id アンケート結果ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        counselorSurveyResultsIdGet(id: number, options?: any) {
            return SurveyResultApiFp(configuration).counselorSurveyResultsIdGet(id, options)(axios, basePath);
        },
    };
};

/**
 * SurveyResultApi - object-oriented interface
 * @export
 * @class SurveyResultApi
 * @extends {BaseAPI}
 */
export class SurveyResultApi extends BaseAPI {
    /**
     * 
     * @summary アンケート結果を取得する
     * @param {number} [employeeId] 従業員ID
     * @param {number} [organizationId] 組織ID
     * @param {number} [page] page
     * @param {number} [limit] limit
     * @param {boolean} [orderByAlert] アラート順にソート
     * @param {number} [surveyDeliveryId] アンケート配信ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SurveyResultApi
     */
    public adminSurveyResultsGet(employeeId?: number, organizationId?: number, page?: number, limit?: number, orderByAlert?: boolean, surveyDeliveryId?: number, options?: any) {
        return SurveyResultApiFp(this.configuration).adminSurveyResultsGet(employeeId, organizationId, page, limit, orderByAlert, surveyDeliveryId, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary アンケート結果詳細を取得する
     * @param {number} id アンケート結果ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SurveyResultApi
     */
    public adminSurveyResultsIdGet(id: number, options?: any) {
        return SurveyResultApiFp(this.configuration).adminSurveyResultsIdGet(id, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary アンケート結果詳細を取得する
     * @param {number} id アンケート結果ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SurveyResultApi
     */
    public counselorSurveyResultsIdGet(id: number, options?: any) {
        return SurveyResultApiFp(this.configuration).counselorSurveyResultsIdGet(id, options)(this.axios, this.basePath);
    }

}


