import { RedirectState, init } from './RedirectState';
import { RedirectAction, Actions } from './RedirectAction';

export const RedirectReducer = (
  state: RedirectState = init(),
  action: RedirectAction,
): RedirectState => {
  switch (action.type) {
    case Actions.UPDATE_REDIRECT: {
      const newState = action.payload as RedirectState;
      return newState;
    }
    case Actions.CLEAR_REDIRECT:
      return init();
    default:
      return state;
  }
};
