import React, { useEffect, useState } from 'react';
import {
  format,
} from 'date-fns';
import {
  Box,
  Typography,
  Grid,
  CircularProgress,
} from '@material-ui/core';

import { ScheduleFramesByDay } from '../../../components/counselor/ScheduleFramesByDay';
import { SideMenuLayout } from '../../../layouts';
import { withAuth, AuthedComponentProps } from '../../../auth/Authenticated';
import { usePrivateApi } from '../../../api/useApi';
import { Toast } from '../../../components/common/Toast';
import {
  ScheduleFrameApi, ScheduleFrameResponse,
} from '../../../generated';

const Content = ({ session }: AuthedComponentProps) => {
  const [loading, setLoading] = useState(false);
  const { api } = usePrivateApi(ScheduleFrameApi);
  const [scheduleFrames, setScheduleFrames] = useState<ScheduleFrameResponse[][]>();
  const [toastMessage, setToastMessage] = useState();

  useEffect(() => {
    setLoading(true);
    const reqSchedules = api.counselorCounselorIdScheduleFramesGet(Number(session.principal.id));
    Promise.all([reqSchedules]).then(([schedulesRes]) => {
      const frames = schedulesRes.data.scheduleFrames;
      if (frames !== undefined) {
        const now = format(new Date(), 'yyyy-MM-dd');
        const dates = Array.from(
          new Set(frames.map(f => f.scheduleDate)
            .filter(d => d > now)),
        )
          .sort();
        const sortedFrames = dates.map(d => frames.filter(f => f.scheduleDate === d));
        setScheduleFrames(sortedFrames);
      }
      setLoading(false);
    });
  }, [session]);


  function deleteFrame(frameId: number) {
    api.counselorScheduleFramesScheduleFrameIdDelete(frameId).then(() => {
      const reqSchedules = api.counselorCounselorIdScheduleFramesGet(Number(session.principal.id));
      Promise.all([reqSchedules]).then(([schedulesRes]) => {
        const frames = schedulesRes.data.scheduleFrames;
        if (frames !== undefined) {
          const now = format(new Date(), 'yyyy-MM-dd');
          const dates = Array.from(
            new Set(frames.map(f => f.scheduleDate)
              .filter(d => d > now)),
          )
            .sort();
          const sortedFrames = dates.map(d => frames.filter(f => f.scheduleDate === d));
          setScheduleFrames(sortedFrames);
          setToastMessage(`削除成功しました (${format(new Date(), 'HH時mm分ss秒')})`);
        }
      });
    }).catch(() => {
      setToastMessage(`削除エラーが発生しました。この受付時間は既に予約されている可能性があります (${format(new Date(), 'HH時mm分ss秒')}時点)`);
    }).finally(() => {
    });
  }

  if (loading) {
    return (
      <Grid container justify="center">
        <CircularProgress />
      </Grid>
    );
  }
  return (
    <>
      <Box display="flex">
        <Box flexGrow={1}>
          <Typography variant="h4" component="h1">
            受付中日時一覧
          </Typography>
        </Box>
      </Box>
      {scheduleFrames
        ? scheduleFrames.map(framesByDay => (
          <ScheduleFramesByDay
            scheduleFrames={framesByDay}
            deleteMethod={deleteFrame}
            key={framesByDay[0].scheduleDate}
          />
        ))
        : <></>
      }
      <Toast
        message={toastMessage}
        duration={3000}
      />
    </>
  );
};

export const ScheduleFrameIndexPage = SideMenuLayout(withAuth(Content));
