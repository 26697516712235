import actionCreatorFactory from 'typescript-fsa';
import { SessionState } from './SessionState';

export const UPDATE_SESSION = 'UPDATE_SESSION';
export const CLEAR_SESSION = 'CREAR_SESSION';

export const Actions = {
  UPDATE_SESSION,
  CLEAR_SESSION,
};

const actionCreator = actionCreatorFactory();
export const updateSession = actionCreator<SessionState>(UPDATE_SESSION);
export const clearSession = actionCreator(CLEAR_SESSION);

export type UpdateSession = ReturnType<typeof updateSession>;
export type ClearSession = ReturnType<typeof clearSession>;

export type SessionAction = UpdateSession | ClearSession;
