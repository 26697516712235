import React from 'react';
import { RadioQuestionResult, RadioQuestion } from './RadioQuestionResult';
import { TextFieldQuestionResult, TextFieldQuestion } from './TextFieldQuestionResult';
import { CheckBoxQuestionResult, CheckBoxQuestion } from './CheckBoxQuestionResult';
import { SliderQuestionResult, SliderQuestion } from './SliderQuestionResult';

export interface Answer {
  id: number;
  questionId: number;
  value: string;
}

export type Question = RadioQuestion | CheckBoxQuestion | TextFieldQuestion | SliderQuestion;

interface IProps {
  index: number;
  question: Question;
  answers: Answer[];
}

const Content = ({ index, question, answers }: IProps) => {
  switch (question.type) {
    case 'radio':
      return <RadioQuestionResult index={index} question={question} answer={answers[0]} />;
    case 'text':
      return <TextFieldQuestionResult index={index} question={question} answer={answers[0]} />;
    case 'slider':
      return <SliderQuestionResult index={index} question={question} answer={answers[0]} />;
    case 'checkbox':
      return <CheckBoxQuestionResult index={index} question={question} answers={answers} />;
    default:
      return null;
  }
};

export const QuestionResult = Content;
