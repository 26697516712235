import React, { useState, useEffect } from 'react';
import {
  Button,
  Box,
  Typography,
  Paper,
  CircularProgress,
} from '@material-ui/core';
import useForm, { FormContext } from 'react-hook-form';
import EditIcon from '@material-ui/icons/Edit';
import useReactRouter from 'use-react-router';
import { format } from 'date-fns';
import { SideMenuLayout } from '../../../layouts';
import {
  UpdateCounselingRequest,
  CounselingApi,
  CounselingAdvice,
  CounselingFormResponse,
  CounselingDetailResponse,
  CounselingResultItemFormTypeEnum,
} from '../../../generated';
import { withAuth, AuthedComponentProps } from '../../../auth/Authenticated';
import { usePrivateApi } from '../../../api/useApi';
import { CounselingCheckBoxForm } from '../../../components/counseling/CounselingCheckBoxForm';
import { CounselingSelectForm } from '../../../components/counseling/CounselingSelectForm';
import { CounselingDateTimeForm } from '../../../components/counseling/CounselingDateTimeForm';
import { CounselingTextFieldForm } from '../../../components/counseling/CounselingTextFieldForm';

const Content: React.FunctionComponent<AuthedComponentProps> = (
  { session }: AuthedComponentProps,
) => {
  const { match, history } = useReactRouter<{ id: string }>();
  const formMethods = useForm();
  const {
    handleSubmit, getValues, setValue,
  } = formMethods;
  const { api, loading } = usePrivateApi(CounselingApi);
  const [counselingResponse, setCounselingResponse] = useState<CounselingDetailResponse>();
  const [formResponse, setFormResponse] = useState<CounselingFormResponse>();

  useEffect(() => {
    const id = Number(match.params.id);
    const formRequest = api.counselingsFormGet();
    const detailRequest = api.adminCounselingsReservationIdGet(id);
    Promise.all([formRequest, detailRequest])
      .then(([formRes, detailRes]) => {
        setFormResponse(formRes.data);
        setCounselingResponse(detailRes.data);
      });
  }, [match, session]);

  useEffect(() => {
    if (!counselingResponse || !formResponse) return;

    const { counseledTimestamp, nextCounselingTimestamp } = counselingResponse;
    setValue('comment', counselingResponse.comment);
    if (counseledTimestamp) {
      setValue('counseledTimestamp', format(new Date(counseledTimestamp), "yyyy-MM-dd'T'HH:mm"));
    }
    if (nextCounselingTimestamp) {
      setValue('nextCounselingTimestamp', format(new Date(nextCounselingTimestamp), "yyyy-MM-dd'T'HH:mm"));
    }

    const resultItems = counselingResponse.counselingResultItems;
    resultItems.forEach(resultItem => {
      resultItem.answer.forEach(a => {
        type Key = keyof CounselingFormResponse;
        if (!resultItem.titleCode) return;
        const index = formResponse[resultItem.titleCode as Key].map(i => i.code).indexOf(a.code);
        if (resultItem.formType === CounselingResultItemFormTypeEnum.Checkbox) {
          setValue(`${resultItem.titleCode}[${index}]`, a.code);
        } else {
          setValue(`${resultItem.titleCode}`, a.code);
        }
      });
    });
  }, [counselingResponse, loading]);

  const onSubmit = (data: any) => {
    const values = getValues();
    const updateCounseling: UpdateCounselingRequest = {
      counseledTimestamp: new Date(values.counseledTimestamp as Date),
      comment: values.comment as string,
      counselingOpportunity: data.counselingOpportunity,
      counselingAdviceList: data.counselingAdvice
        .filter((i: CounselingAdvice | false) => i !== false),
      mainStressorList: data.mainStressor.filter((i: CounselingAdvice | false) => i !== false),
      urgency: data.urgency,
      retirementPossibility: data.retirementPossibility,
      absencePossibility: data.absencePossibility,
      industrialDoctorCounseling: data.industrialDoctorCounseling,
      encourageConsultation: data.encourageConsultation,
      hrSupport: data.hrSupport,
      organizationalSupport: data.organizationalSupport,
      businessConsiderations: data.businessConsiderations,
      reportToHr: data.reportToHr,
      nextCounseling: data.nextCounseling,
      nextCounselingTimestamp: new Date(data.nextCounselingTimestamp as Date),
    };

    const req = api.adminCounselingsReservationIdPut(
      Number(match.params.id), updateCounseling,
    );
    req.then(_res => history.push('/admin/counselings'));
  };

  if (loading || !formResponse) return <CircularProgress />;
  if (!loading && counselingResponse) {
    return (
      <>
        <Typography variant="h4" component="h1">
          面談記録編集ページ
        </Typography>
        <FormContext {...formMethods}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Paper>
              <CounselingDateTimeForm title="面談実施日時" name="counseledTimestamp" required />
              <CounselingSelectForm
                title={counselingResponse.counselingResultItems[0].titleDisplayName}
                name={counselingResponse.counselingResultItems[0].titleCode}
                items={formResponse.counselingOpportunity}
                required
              />
              <CounselingCheckBoxForm
                title={counselingResponse.counselingResultItems[1].titleDisplayName}
                name={counselingResponse.counselingResultItems[1].titleCode}
                items={formResponse.counselingAdvice}
                required
                row
              />
              <CounselingCheckBoxForm
                title={counselingResponse.counselingResultItems[2].titleDisplayName}
                name={counselingResponse.counselingResultItems[2].titleCode}
                items={formResponse.mainStressor}
                required
              />
              <Box display="flex">
                <CounselingSelectForm
                  title={counselingResponse.counselingResultItems[3].titleDisplayName}
                  name={counselingResponse.counselingResultItems[3].titleCode}
                  items={formResponse.urgency}
                  required
                />
                <CounselingSelectForm
                  title={counselingResponse.counselingResultItems[4].titleDisplayName}
                  name={counselingResponse.counselingResultItems[4].titleCode}
                  items={formResponse.retirementPossibility}
                  required
                />
                <CounselingSelectForm
                  title={counselingResponse.counselingResultItems[5].titleDisplayName}
                  name={counselingResponse.counselingResultItems[5].titleCode}
                  items={formResponse.absencePossibility}
                  required
                />
              </Box>
              <Box display="flex">
                <CounselingSelectForm
                  title={counselingResponse.counselingResultItems[6].titleDisplayName}
                  name={counselingResponse.counselingResultItems[6].titleCode}
                  items={formResponse.industrialDoctorCounseling}
                  required
                />
                <CounselingSelectForm
                  title={counselingResponse.counselingResultItems[7].titleDisplayName}
                  name={counselingResponse.counselingResultItems[7].titleCode}
                  items={formResponse.encourageConsultation}
                  required
                />
                <CounselingSelectForm
                  title={counselingResponse.counselingResultItems[8].titleDisplayName}
                  name={counselingResponse.counselingResultItems[8].titleCode}
                  items={formResponse.hrSupport}
                  required
                />
              </Box>
              <Box display="flex">
                <CounselingSelectForm
                  title={counselingResponse.counselingResultItems[9].titleDisplayName}
                  name={counselingResponse.counselingResultItems[9].titleCode}
                  items={formResponse.organizationalSupport}
                  required
                />
                <CounselingSelectForm
                  title={counselingResponse.counselingResultItems[10].titleDisplayName}
                  name={counselingResponse.counselingResultItems[10].titleCode}
                  items={formResponse.businessConsiderations}
                  required
                />
                <CounselingSelectForm
                  title={counselingResponse.counselingResultItems[11].titleDisplayName}
                  name={counselingResponse.counselingResultItems[11].titleCode}
                  items={formResponse.reportToHr}
                  required
                />
              </Box>
              <CounselingSelectForm
                title={counselingResponse.counselingResultItems[12].titleDisplayName}
                name={counselingResponse.counselingResultItems[12].titleCode}
                items={formResponse.nextCounseling}
                required
              />
              <CounselingDateTimeForm title="次回面談の日時" name="nextCounselingTimestamp" />

              <CounselingTextFieldForm title="面談コメント" name="comment" />
            </Paper>
            <Box display="flex" mt={2}>
              <Box flexGrow={1} />
              <Box>
                <Button
                  variant="contained"
                  color="primary"
                  type="submit"
                >
                  <EditIcon />
                  更新する
                </Button>
              </Box>
            </Box>
          </form>
        </FormContext>
      </>
    );
  }

  return <p>404 not found</p>;
};

export const AdminCounselingEditPage = SideMenuLayout(withAuth(Content));
