import React, { useState, useEffect } from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import {
  Button,
  Box,
  Typography,
  Checkbox,
  Paper,
  CircularProgress,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@material-ui/core';
import useReactRouter from 'use-react-router';
import { SideMenuLayout } from '../../layouts';
import {
  CounselingApi, CounselorResponse, OrganizationApi, OrganizationResponse,
} from '../../generated';
import { withAuth, AuthedComponentProps } from '../../auth/Authenticated';
import { usePrivateApi } from '../../api/useApi';

const Content: React.FunctionComponent<AuthedComponentProps> = (
  { session }: AuthedComponentProps,
) => {
  const { history } = useReactRouter();
  const [loading, setLoading] = useState(false);
  const { api: counselingApi } = usePrivateApi(CounselingApi);
  const { api: organizationApi } = usePrivateApi(OrganizationApi);
  const [openDialog, setOpenDialog] = useState(false);
  const { match } = useReactRouter<{ organizationId: string }>();

  const [checkedCounselors, setCheckedCounselors] = useState<CounselorResponse[]>([]);
  const [leftCounselors, setLeftCounselors] = useState<CounselorResponse[]>([]);
  const [rightCounselors, setRightCounselors] = useState<CounselorResponse[]>([]);
  const [organization, setOrganization] = useState<OrganizationResponse>();

  function not(a: CounselorResponse[], b: CounselorResponse[]) {
    return a.filter(value => b.indexOf(value) === -1);
  }

  function intersection(a: CounselorResponse[], b: CounselorResponse[]) {
    return a.filter(value => b.indexOf(value) !== -1);
  }
  const leftCheckedCounselors = intersection(checkedCounselors, leftCounselors);
  const rightCheckedCounselors = intersection(checkedCounselors, rightCounselors);

  const useStyles = makeStyles((theme: Theme) => createStyles({
    root: {
      margin: 'auto',
    },
    listPaper: {
      width: 300,
      height: 400,
      overflow: 'auto',
    },
    controlPanel: {
      height: 300,
      width: 150,
      overflow: 'auto',
    },
    listButton: {
      margin: theme.spacing(0.5, 0),
      backgroundColor: '#fff',
    },
    alerterd: {
      color: '#AF1831',
    },
    tooltip: {
      fontSize: theme.typography.pxToRem(14),
    },
  }));
  const classes = useStyles();

  const submit = () => {
    const organizationId = Number(match.params.organizationId);
    const counselorIds = rightCounselors.map(c => Number(c.id)) || [];
    const req = counselingApi.adminCounselorsOrganizationRelationPost(
      organizationId,
      counselorIds,
    );
    req.then(() => {
      history.push('/admin/organizations');
    });
  };

  useEffect(() => {
    setLoading(true);
    const organizationId = Number(match.params.organizationId);
    const allCounselorReq = counselingApi.adminCounselorsGet();
    const relatedCounselorReq = counselingApi.adminCounselorsGet(organizationId);
    const organizationReq = organizationApi.adminOrganizationsGet();

    // contractInfoのpromiseを監視しないのは意図的。このリクエストは契約情報が入ってないと500を返すため
    Promise.all([allCounselorReq, relatedCounselorReq, organizationReq])
      .then(([allCounselorRes, relatedCounselorRes, organizationRes]) => {
        const notRelatedCounselors = allCounselorRes.data
          .filter(counselor => relatedCounselorRes.data.map(c => c.id).indexOf(counselor.id) < 0);
        const organizationData = organizationRes.data.find(org => org.id === organizationId);
        setLeftCounselors(notRelatedCounselors);
        setRightCounselors(relatedCounselorRes.data);
        setOrganization(organizationData);
      }).finally(() => {
        setLoading(false);
      });
  }, [session]);

  const handleToggle = (counselor: CounselorResponse) => () => {
    const currentIndex = checkedCounselors.indexOf(counselor);
    const newCheckedCounselors = [...checkedCounselors];

    if (currentIndex === -1) {
      newCheckedCounselors.push(counselor);
    } else {
      newCheckedCounselors.splice(currentIndex, 1);
    }

    setCheckedCounselors(newCheckedCounselors);
  };

  const handleCheckedRight = () => {
    setRightCounselors(rightCounselors.concat(leftCheckedCounselors));
    setLeftCounselors(not(leftCounselors, leftCheckedCounselors));
    setCheckedCounselors(not(checkedCounselors, leftCheckedCounselors));
  };

  const handleCheckedLeft = () => {
    setLeftCounselors(leftCounselors.concat(rightCheckedCounselors));
    setRightCounselors(not(rightCounselors, rightCheckedCounselors));
    setCheckedCounselors(not(checkedCounselors, rightCheckedCounselors));
  };

  const handleAllLeft = () => {
    setLeftCounselors(leftCounselors.concat(rightCounselors));
    setRightCounselors([]);
  };

  const handleAllRight = () => {
    setRightCounselors(rightCounselors.concat(leftCounselors));
    setLeftCounselors([]);
  };

  const handleClickOpenDialog = () => {
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const customList = (counselors: CounselorResponse[]) => (
    <Paper className={classes.listPaper}>
      <List dense component="div" role="list">
        {counselors.map((counselor: CounselorResponse) => {
          const labelId = `transfer-list-item-${counselor}-label`;
          return (
            <ListItem key={counselor.id} role="listitem" button onClick={handleToggle(counselor)}>
              <ListItemIcon>
                <Checkbox
                  checked={checkedCounselors.indexOf(counselor) !== -1}
                  tabIndex={-1}
                  disableRipple
                  inputProps={{ 'aria-labelledby': labelId }}
                />
              </ListItemIcon>
              {counselor.name}
            </ListItem>
          );
        })}
        <ListItem />
      </List>
    </Paper>
  );

  if (loading) return <CircularProgress />;
  return (
    <>
      <Box display="flex">
        <Box flexGrow={1}>
          <Typography variant="h4" component="h1">
          担当可能なカウンセラーの設定：
            {' '}
            {organization && organization.name}
          </Typography>
        </Box>
      </Box>

      <Grid container spacing={2} alignItems="center" className={classes.root}>
        <Grid item>
          {customList(leftCounselors)}
        </Grid>
        <Grid item>
          <Grid container direction="column" alignItems="center">
            <Button
              variant="outlined"
              size="small"
              className={classes.listButton}
              onClick={handleCheckedRight}
              disabled={leftCheckedCounselors.length === 0}
              aria-label="move selected right"
            >
            &gt;
            </Button>
            <Button
              variant="outlined"
              size="small"
              className={classes.listButton}
              onClick={handleCheckedLeft}
              disabled={rightCheckedCounselors.length === 0}
              aria-label="move selected left"
            >
            &lt;
            </Button>
            <Button
              variant="outlined"
              size="small"
              className={classes.listButton}
              onClick={handleAllRight}
              disabled={leftCounselors.length === 0}
              aria-label="move all right"
            >
            一括設定
            </Button>
            <Button
              variant="outlined"
              size="small"
              className={classes.listButton}
              onClick={handleAllLeft}
              disabled={rightCounselors.length === 0}
              aria-label="move all left"
            >
            一括解除
            </Button>
          </Grid>
        </Grid>
        <Grid item>
          {customList(rightCounselors)}
        </Grid>
      </Grid>
      <Box display="flex" mt={2}>
        <Box>
          <Button
            variant="contained"
            color="primary"
            onClick={handleClickOpenDialog}
          >
            担当を設定する
          </Button>
        </Box>
      </Box>
      <Dialog
        open={openDialog}
        onClose={handleCloseDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">担当設定の確認</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <p>
              企業:
              {' '}
              {organization && organization.name}
              {' '}
に対して以下のカウンセラーを担当可能にします。
            </p>
            <ul>
              {rightCounselors.map(c => <li>{c.name}</li>)}
            </ul>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} color="primary">
            キャンセル
          </Button>
          <Button onClick={handleCloseDialog && submit} color="primary" autoFocus>
            設定する
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
export const CounselorRelationManagePage = SideMenuLayout(withAuth(Content));
