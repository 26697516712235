import React from 'react';
import { Button, Grid } from '@material-ui/core';
import firebase from 'firebase';
import { useDispatch, useSelector } from 'react-redux';
import useReactRouter from 'use-react-router';
import { clearSession } from '../redux/session/SessionAction';
import { MainLayout } from '../layouts';
import { redirectSelector } from '../foundation/Store';

const Content = () => {
  const dispatch = useDispatch();
  const { history } = useReactRouter();
  const redirectUrl = useSelector(redirectSelector);

  const relogin = () => {
    dispatch(clearSession());
    history.push('/auth/signin');
  };

  const sendVerifyMail = async () => {
    const user = firebase.auth().currentUser;
    if (user) {
      user.sendEmailVerification({ url: window.location.origin + redirectUrl });
    }
  };

  return (
    <Grid
      container
      spacing={2}
      alignContent="center"
      justify="center"
      style={{ minHeight: '100vh' }}
    >
      <Grid
        item
        style={{
          background: 'white',
          padding: '20px',
          paddingTop: '10px',
          borderRadius: '5px',
          width: '500px',
        }}
      >
        <h2>メール認証未完了</h2>
        <p>
          ユーザ作成が完了しました。
        </p>
        <p>
          お客様のアカウントはまだメール認証が完了していません。
          ご登録のメールアドレスをご確認の上、URLをクリックして認証を完了してください。
        </p>
        <Grid container spacing={2}>
          <Grid item>
            <Button color="primary" onClick={() => sendVerifyMail()}>認証メールを再送する</Button>
          </Grid>
          <Grid item>
            <Button color="primary" onClick={() => relogin()}>再ログインする</Button>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export const NotVerifiedPage = MainLayout(Content);
