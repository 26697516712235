import React, { useEffect } from 'react';
import {
  FormControl, FormLabel, Box, Select, MenuItem, FormHelperText, makeStyles, createStyles,
} from '@material-ui/core';
import { useFormContext } from 'react-hook-form';
import { CounselingFormItem } from '../../generated';

interface IProps {
  title: string;
  name: string;
  required?: boolean;
  readOnly?: boolean;
  items: CounselingFormItem[];
}

const useStyles = makeStyles(() => createStyles({
  formControl: {
    minWidth: 200,
  },
}));

const Content = ({
  title, name, required, readOnly, items,
}: IProps) => {
  const classes = useStyles();
  const {
    register, setValue, watch, errors,
  } = useFormContext();

  useEffect(() => {
    if (required) register({ name }, { required: '必須項目です' });
  }, []);

  const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setValue(name, event.target.value);
  };

  const error = errors[name];

  return (
    <Box p={2}>
      <FormControl required={required} variant="outlined" className={classes.formControl}>
        <FormLabel>{title}</FormLabel>

        <Select
          name={name}
          value={watch(name) || ''}
          onChange={handleChange}
          readOnly={readOnly}
        >
          {
            items.map(item => (
              <MenuItem key={item.code} value={item.code}>{item.displayName}</MenuItem>
            ))
          }
        </Select>
        {
          error && (
            <FormHelperText error>
              {error.message}
            </FormHelperText>
          )}

      </FormControl>
    </Box>
  );
};
export const CounselingSelectForm = Content;
