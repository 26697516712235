import React from 'react';
import {
  FormControl, FormLabel, FormGroup, FormControlLabel, Box, Checkbox, FormHelperText,
} from '@material-ui/core';
import { useFormContext } from 'react-hook-form';
import { CounselingFormItem } from '../../generated';

interface IProps {
  title: string;
  name: string;
  required?: boolean;
  row?: boolean;
  readOnly?: boolean;
  items: CounselingFormItem[];
}

const Content = ({
  title, name, required, row, items, readOnly,
}: IProps) => {
  const {
    register, watch, errors, setValue,
  } = useFormContext();
  const ref = required
    ? register({
      validate: () => {
        const count = watch(name).filter((v: string | false) => v !== false).length;
        return count > 0 || '1つ以上選択してください';
      },
    })
    : register;

  const errorMessages = () => {
    const reg = new RegExp(`${name}\\[\\d+\\]`);
    const message = Object.entries(errors)
      .filter(([key, value]) => {
        if (value == null) return false;
        return reg.test(key);
      })
      .map(([_, value]) => value!.message);
    return Array.from(new Set(message));
  };

  return (
    <Box m={2}>
      <FormControl required={required}>
        <FormLabel>{title}</FormLabel>
        <FormGroup row={row}>
          {
            items.map((item, index) => (
              <FormControlLabel
                key={item.code}
                name={`${name}[${index}]`}
                value={item.code}
                label={item.displayName}
                checked={Boolean(watch(`${name}[${index}]`)).valueOf()}
                onChange={(_, checked) => {
                  if (readOnly) return;
                  setValue(`${name}[${index}]`, checked);
                }}
                control={<Checkbox color="primary" />}
                inputRef={ref}
              />
            ))
          }
        </FormGroup>
        {errorMessages().map(errorMessage => (
          <FormHelperText error key={errorMessage}>
            {errorMessage}
          </FormHelperText>
        ))}
      </FormControl>
    </Box>
  );
};
export const CounselingCheckBoxForm = Content;
