import * as Sentry from '@sentry/browser';
import { SessionState, init } from './SessionState';
import { SessionAction, Actions } from './SessionAction';

export const sessionReducer = (
  session: SessionState = init(),
  action: SessionAction,
): SessionState => {
  switch (action.type) {
    case Actions.UPDATE_SESSION: {
      if (action.payload) {
        const newSession: SessionState = action.payload;
        if (newSession === 'guest') {
          Sentry.configureScope(scope => { scope.setUser({ id: 'guest' }); });
        } else {
          Sentry.configureScope(scope => { scope.setUser({ id: newSession.principal.id }); });
        }
        return newSession;
      }
      return session;
    }
    case Actions.CLEAR_SESSION:
      Sentry.configureScope(scope => { scope.setUser({ id: 'guest' }); });
      return 'guest';
    default:
      return session;
  }
};
