import React from 'react';
import { Redirect } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import useReactRouter from 'use-react-router';
import { sessionSelector } from '../foundation/Store';
import { LoggedIn } from '../redux/session/SessionState';
import { updateRedirect } from '../redux/redirect/RedirectAction';

export interface AuthedComponentProps {
  session: LoggedIn;
}

/**
 * 認証済みのときだけComponentを返すHOC
 * 認証済みであればLoggedInをComponentに渡し、そうでなければLoginページにリダイレクトする
 * @param Component ラップしたいコンポーネント
 */
export const withAuth = (Component: React.ComponentType<AuthedComponentProps>) => () => {
  const session = useSelector(sessionSelector);
  const { match, location } = useReactRouter();
  const dispatch = useDispatch();
  const isNotVerifiedPath = /\/auth\/not-verified/.test(location.pathname);

  if (session === 'guest') {
    if (!isNotVerifiedPath) {
      dispatch(updateRedirect(match.url));
    }
    return <Redirect to="/auth/signin" />;
  }

  // 未認証ユーザは未認証ページに飛ばす
  if (!session.isVerified && !isNotVerifiedPath) {
    return <Redirect to="/auth/not-verified" />;
  }

  return (<Component session={session} />);
};

export const withoutAuth = (Component: React.ComponentType) => () => {
  const session = useSelector(sessionSelector);

  if (session !== 'guest') {
    return <Redirect to="/" />;
  }
  return <Component />;
};
