import React from 'react';
import {
  FormControl,
  FormLabel,
  makeStyles,
  createStyles,
  Theme,
  Box,
} from '@material-ui/core';
import { ChoiceResponse } from '../../generated';

const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {
    width: '100%',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
}));

export interface Answer {
  id: number;
  questionId: number;
  value: string;
}

export interface SliderQuestion {
  id: number;
  title: string;
  type: 'slider';
  choices: Array<ChoiceResponse>;
}

interface IProps {
  index: number;
  question: SliderQuestion;
  answer?: Answer;
}

const Content = ({ index, question, answer }: IProps) => {
  const classes = useStyles();
  return (
    <FormControl className={classes.root}>
      <Box whiteSpace="pre-line">
        <FormLabel>{`Q${index + 1}: ${question.title}`}</FormLabel>
      </Box>
      <Box>
        {answer && answer.value}
      </Box>
    </FormControl>
  );
};

export const SliderQuestionResult = Content;
