import React from 'react';
import {
  FormControl, FormLabel, FormControlLabel, Radio, RadioGroup, makeStyles, createStyles, Theme, Box,
} from '@material-ui/core';
import { ChoiceResponse } from '../../generated';

const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {
    width: '100%',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
}));

export interface Answer {
  id: number;
  questionId: number;
  value: string;
}

export interface RadioQuestion {
  id: number;
  title: string;
  type: 'radio';
  choices: Array<ChoiceResponse>;
}

interface IProps {
  index: number;
  question: RadioQuestion;
  answer?: Answer;
}

const Content = ({ index, question, answer }: IProps) => {
  const classes = useStyles();
  return (
    <FormControl className={classes.root}>
      <Box whiteSpace="pre-line">
        <FormLabel>
          {`Q${index + 1}: ${question.title}`}
        </FormLabel>
      </Box>
      <RadioGroup row value={answer && answer.value}>
        {
          question.choices.map((choice: ChoiceResponse) => (
            <FormControlLabel
              value={choice.value}
              control={(<Radio color="primary" />)}
              label={choice.title}
            />
          ))
        }
      </RadioGroup>
    </FormControl>
  );
};

export const RadioQuestionResult = Content;
