import { combineReducers, createStore, compose } from 'redux';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/es/storage';
import { useSelector } from 'react-redux';
import { SessionState } from '../redux/session/SessionState';
import { sessionReducer } from '../redux/session/SessionReducer';
import { RedirectState } from '../redux/redirect/RedirectState';
import { RedirectReducer } from '../redux/redirect/RedirectReducer';

// for redux-devtools
declare global {
  interface Window {
    // eslint-disable-next-line no-undef
    __REDUX_DEVTOOLS_EXTENSION_COMPOSE__?: typeof compose;
  }
}

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

export interface AppState {
  session: SessionState;
  redirect: RedirectState;
}

const rootReducer = combineReducers<AppState>({
  session: sessionReducer,
  redirect: RedirectReducer,
});

const persistConfig = {
  key: 'root', // storage格納時のKey名
  storage,
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const sessionSelector = (state: AppState): SessionState => state.session;
export const redirectSelector = (state: AppState): RedirectState => state.redirect;
export const useSession = () => useSelector(sessionSelector);

/**
 * Storeの初期化関数
 *
 * @param callback 初期化完了時に実行されるコールバック関数
 */
export const configureStore = (callback?: () => any | undefined) => {
  const store = createStore(
    persistedReducer,
    composeEnhancers(),
  );
  const persistor = persistStore(store, {}, callback);

  return { store, persistor };
};
