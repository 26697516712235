import React from 'react';
import { useFormContext } from 'react-hook-form';
import {
  Box, FormLabel, TextField, FormHelperText,
} from '@material-ui/core';

interface IProps {
  title: string;
  name: string;
  required?: boolean;
  readOnly?: boolean;
}

const Content = ({
  title, name, readOnly,
}: IProps) => {
  const { register, errors } = useFormContext();

  return (
    <Box p={2}>
      <FormLabel component="legend">{title}</FormLabel>
      <TextField
        name={name}
        multiline
        fullWidth
        type="text"
        rows="8"
        InputProps={{
          readOnly,
        }}
        inputRef={register({ maxLength: { value: 10000, message: '面談コメントは10000文字以内で入力して下さい' } })}
      />
      {
        errors.comment && (
          <FormHelperText error>
            {errors.comment.message}
          </FormHelperText>
        )
      }
    </Box>
  );
};

export const CounselingTextFieldForm = Content;
