import React from 'react';
import { Redirect } from 'react-router';
import { useDispatch } from 'react-redux';
import { clearRedirect } from '../../redux/redirect/RedirectAction';
import { clearSession } from '../../redux/session/SessionAction';

const Content = () => {
  const dispatch = useDispatch();

  dispatch(clearSession());
  dispatch(clearRedirect());

  return <Redirect to="/auth/signin" />;
};

export const LogoutPage = Content;
