import React, { useEffect, useState } from 'react';
import {
  Button,
  Box,
  Typography,
  Paper,
  CircularProgress,
  TableRow,
  TableCell,
  TableHead,
  TableBody,
} from '@material-ui/core';
import { format } from 'date-fns';
import ja from 'date-fns/locale/ja';
import EditIcon from '@material-ui/icons/Edit';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import CheckedIcon from '@material-ui/icons/AssignmentTurnedInOutlined';
import useForm, { FormContext } from 'react-hook-form';
import useReactRouter from 'use-react-router';
import { Link } from 'react-router-dom';
import { SideMenuLayout } from '../../../layouts';
import { LinkForward } from '../../../components/common/LinkForward';
import { withAuth, AuthedComponentProps } from '../../../auth/Authenticated';
import { usePrivateApi } from '../../../api/useApi';
import {
  EmployeeApi,
  CounselingApi,
  CounselingFormResponse,
  CounselingDetailResponse,
  CounselingResultItemFormTypeEnum,
  CounselingDetailResponseStatusEnum,
  SurveyResultsResponse,
} from '../../../generated';
import { CounselingSelectForm } from '../../../components/counseling/CounselingSelectForm';
import { CounselingCheckBoxForm } from '../../../components/counseling/CounselingCheckBoxForm';


const Content: React.FunctionComponent<AuthedComponentProps> = (
  { session }: AuthedComponentProps,
) => {
  const { match } = useReactRouter<{ id: string }>();
  const formMethods = useForm();
  const { setValue } = formMethods;
  const [counselingResponse, setCounselingResponse] = useState<CounselingDetailResponse>();
  const [surveyResponse, setSurveyResponse] = useState<SurveyResultsResponse>();
  const [formResponse, setFormResponse] = useState<CounselingFormResponse>();
  const statusJp: { [key: string]: string } = { reservation: '面談未実施', cancel: 'キャンセル済', counseled: '実施済' };
  const { api: counselingApi, loading: counselingLoading } = usePrivateApi(CounselingApi);
  const { api: employeeApi, loading: employeeLoading } = usePrivateApi(EmployeeApi);
  const id = Number(match.params.id);

  useEffect(() => {
    const formRequest = counselingApi.counselingsFormGet();
    const detailRequest = counselingApi.counselorCounselingsReservationIdGet(id);
    Promise.all([formRequest, detailRequest])
      .then(([formRes, detailRes]) => {
        setFormResponse(formRes.data);
        setCounselingResponse(detailRes.data);
        employeeApi.counselorEmployeeIdSurveyResultsGet(detailRes.data.employeeId).then(res => {
          setSurveyResponse(res.data);
        });
      });
  }, [match, session]);

  useEffect(() => {
    if (!counselingResponse || !formResponse) return;

    const resultItems = counselingResponse.counselingResultItems;
    resultItems.forEach(resultItem => {
      resultItem.answer.forEach(a => {
        type Key = keyof CounselingFormResponse;
        if (!resultItem.titleCode) return;
        const index = formResponse[resultItem.titleCode as Key].map(i => i.code).indexOf(a.code);
        if (resultItem.formType === CounselingResultItemFormTypeEnum.Checkbox) {
          setValue(`${resultItem.titleCode}[${index}]`, a.code);
        } else {
          setValue(`${resultItem.titleCode}`, a.code);
        }
      });
    });
  }, [counselingResponse, counselingLoading]);

  if (employeeLoading || counselingLoading || !formResponse) return <CircularProgress />;
  if (!employeeLoading && !counselingLoading && counselingResponse && surveyResponse) {
    return (
      <>
        <Box display="flex">
          <Box flexGrow={1}>
            <Typography variant="h4" component="h1">
              面談記録詳細ページ
            </Typography>
          </Box>
          <Box>
            <Button
              variant="contained"
              color="primary"
              component={LinkForward(`/counselor/counselings/${match.params.id}/edit`)}
            >
              <EditIcon />
              面談を記録する
            </Button>
          </Box>
        </Box>
        <FormContext {...formMethods}>
          <Paper>
            <Box p={2}>
              <Typography variant="h6" component="h3">従業員名</Typography>
              <Typography component="p">{counselingResponse.employeeName}</Typography>
            </Box>
            <Box p={2}>
              <Typography variant="h6" component="h3">アンケート</Typography>
              <TableHead>
                <TableRow>
                  <TableCell>アンケート名</TableCell>
                  <TableCell>開始日時</TableCell>
                  <TableCell>完了日時</TableCell>
                  <TableCell>調査結果</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {surveyResponse.items.map(x => (
                  <TableRow key={x.id}>
                    <TableCell component="th" scope="row">
                      <Link to={`/counselor/survey-results/${x.id}`} target="_blank">{x.title}</Link>
                    </TableCell>
                    <TableCell>{x.startTimestamp && format(new Date(x.startTimestamp), 'PPP(E) p', { locale: ja })}</TableCell>
                    <TableCell>{x.finishTimestamp && format(new Date(x.finishTimestamp), 'PPP(E) p', { locale: ja })}</TableCell>
                    <TableCell>{x.alerted ? <ErrorOutlineIcon color="error" /> : <CheckedIcon />}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Box>

            <Box p={2}>
              <Typography variant="h6" component="h3">面談予約日時</Typography>
              <Typography component="p">{counselingResponse.reservationTimestamp && format(new Date(counselingResponse.reservationTimestamp), 'PPP(E) p', { locale: ja })}</Typography>
            </Box>
            <Box p={2}>
              <Typography variant="h6" component="h3">面談実施日</Typography>
              <Typography component="p">{counselingResponse.counseledTimestamp && format(new Date(counselingResponse.counseledTimestamp), 'PPP(E) p', { locale: ja })}</Typography>
            </Box>
            <Box p={2}>
              <Typography variant="h6" component="h3">面談ステータス</Typography>
              <Typography component="p">{statusJp[counselingResponse.status]}</Typography>
            </Box>
            {counselingResponse.status === CounselingDetailResponseStatusEnum.Counseled
              ? (
                <>
                  <CounselingSelectForm
                    title={counselingResponse.counselingResultItems[0].titleDisplayName}
                    name={counselingResponse.counselingResultItems[0].titleCode}
                    items={formResponse.counselingOpportunity}
                    required
                    readOnly
                  />
                  <CounselingCheckBoxForm
                    title={counselingResponse.counselingResultItems[1].titleDisplayName}
                    name={counselingResponse.counselingResultItems[1].titleCode}
                    items={formResponse.counselingAdvice}
                    required
                    row
                    readOnly
                  />
                  <CounselingCheckBoxForm
                    title={counselingResponse.counselingResultItems[2].titleDisplayName}
                    name={counselingResponse.counselingResultItems[2].titleCode}
                    items={formResponse.mainStressor}
                    required
                    readOnly
                  />
                  <Box display="flex">
                    <CounselingSelectForm
                      title={counselingResponse.counselingResultItems[3].titleDisplayName}
                      name={counselingResponse.counselingResultItems[3].titleCode}
                      items={formResponse.urgency}
                      required
                      readOnly
                    />
                    <CounselingSelectForm
                      title={counselingResponse.counselingResultItems[4].titleDisplayName}
                      name={counselingResponse.counselingResultItems[4].titleCode}
                      items={formResponse.retirementPossibility}
                      required
                      readOnly
                    />
                    <CounselingSelectForm
                      title={counselingResponse.counselingResultItems[5].titleDisplayName}
                      name={counselingResponse.counselingResultItems[5].titleCode}
                      items={formResponse.absencePossibility}
                      required
                      readOnly
                    />
                  </Box>
                  <Box display="flex">
                    <CounselingSelectForm
                      title={counselingResponse.counselingResultItems[6].titleDisplayName}
                      name={counselingResponse.counselingResultItems[6].titleCode}
                      items={formResponse.industrialDoctorCounseling}
                      required
                      readOnly
                    />
                    <CounselingSelectForm
                      title={counselingResponse.counselingResultItems[7].titleDisplayName}
                      name={counselingResponse.counselingResultItems[7].titleCode}
                      items={formResponse.encourageConsultation}
                      required
                      readOnly
                    />
                    <CounselingSelectForm
                      title={counselingResponse.counselingResultItems[8].titleDisplayName}
                      name={counselingResponse.counselingResultItems[8].titleCode}
                      items={formResponse.hrSupport}
                      required
                      readOnly
                    />
                  </Box>
                  <Box display="flex">
                    <CounselingSelectForm
                      title={counselingResponse.counselingResultItems[9].titleDisplayName}
                      name={counselingResponse.counselingResultItems[9].titleCode}
                      items={formResponse.organizationalSupport}
                      required
                      readOnly
                    />
                    <CounselingSelectForm
                      title={counselingResponse.counselingResultItems[10].titleDisplayName}
                      name={counselingResponse.counselingResultItems[10].titleCode}
                      items={formResponse.businessConsiderations}
                      required
                      readOnly
                    />
                    <CounselingSelectForm
                      title={counselingResponse.counselingResultItems[11].titleDisplayName}
                      name={counselingResponse.counselingResultItems[11].titleCode}
                      items={formResponse.reportToHr}
                      required
                      readOnly
                    />
                  </Box>
                  <CounselingSelectForm
                    title={counselingResponse.counselingResultItems[12].titleDisplayName}
                    name={counselingResponse.counselingResultItems[12].titleCode}
                    items={formResponse.nextCounseling}
                    required
                    readOnly
                  />
                  <Box p={2}>
                    <Typography variant="h6" component="h3">次回面談の日時</Typography>
                    <Typography component="p">
                      {counselingResponse.nextCounselingTimestamp && format(new Date(counselingResponse.nextCounselingTimestamp), 'PPP(E) p', { locale: ja })}
                    </Typography>
                  </Box>
                  <Box p={2} style={{ whiteSpace: 'pre-line' }}>
                    <Typography variant="h6" component="h3">面談コメント</Typography>
                    <Typography component="p">{counselingResponse.comment}</Typography>
                  </Box>
                </>
              )
              : <></>}
          </Paper>
        </FormContext>
      </>
    );
  }

  return <p>404 not found</p>;
};

export const CounselorCounselingDetailPage = SideMenuLayout(withAuth(Content));
