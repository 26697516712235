import React, { useEffect, useState, FormEvent } from 'react';
import {
  format, addMonths, eachDayOfInterval, startOfMonth, endOfMonth, addDays,
} from 'date-fns';
import ja from 'date-fns/locale/ja';
import CheckIcon from '@material-ui/icons/Check';
import {
  Box,
  Typography,
  Button,
  Tabs,
  Tab,
  Paper,
  AppBar,
  Table, TableBody, TableCell, TableRow,
  CircularProgress,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import useReactRouter from 'use-react-router';
import { SideMenuLayout } from '../../../layouts';
import { withAuth, AuthedComponentProps } from '../../../auth/Authenticated';
import { usePrivateApi } from '../../../api/useApi';
import { Toast } from '../../../components/common/Toast';
import {
  ScheduleFrameApi, ScheduleFrameRequest, ScheduleFrameResponse, CounselingResponse, CounselingApi,
} from '../../../generated';

const Content: React.FunctionComponent<AuthedComponentProps> = (
  { session }: AuthedComponentProps,
) => {
  interface Frame {
    date: Date;
    time: number;
  }

  interface TabPanelProps {
    children?: React.ReactNode;
    index: any;
    value: any;
  }

  const { api } = usePrivateApi(ScheduleFrameApi);
  const { api: counselingApi } = usePrivateApi(CounselingApi);
  const [tabValue, setTabValue] = useState(0);
  const [resScheduleFrames, setResScheduleFrames] = useState<Array<ScheduleFrameResponse>>();
  const [toastMessage, setToastMessage] = useState();
  const [counselings, setCounselings] = useState<CounselingResponse[]>();
  const { history } = useReactRouter();
  const [loading, setLoading] = useState(true);

  const useStyles = makeStyles(() => ({
    tab: {
      color: '#ffffff',
      fontWeight: 'bold',
      fontSize: '2em',
    },
    cell: {
      padding: '14px 20px 14px 16px',
      verticalAlign: 'top',
      textAlign: 'center',
    },
    reservationButton: {
      transform: 'scale(1.5)',
      WebkitTransform: 'scale(1.5)',
      msTransform: 'scale(1.5)',
      marginRight: 10,
    },
  }));

  const daysOfOperableMonth = [0, 1, 2].map(plusMonth => addMonths(new Date(), plusMonth));
  const classes = useStyles();
  const handleChange = (event: React.ChangeEvent<{}>, newTabValue: number) => {
    setTabValue(newTabValue);
  };

  const startHour = 10;
  const endHour = 21;

  const selectableHours = [...Array(endHour - startHour)].map((_, i) => (i + startHour));
  function TabPanel(props: TabPanelProps) {
    const {
      children, value, index, ...other
    } = props;

    return (
      <Typography
        component="div"
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        <Box p={3}>{children}</Box>
      </Typography>
    );
  }

  function a11yProps(index: any) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }

  function isEnableDay(day: Date) {
    if ([0, 6].includes(day.getDay())) { return false; } // 土日
    if (addDays(new Date(), 6) > day) { return false; }
    return true;
  }

  function fetchSchduleFrame(date: Date, hour: number): ScheduleFrameResponse | undefined {
    if (resScheduleFrames === undefined) { return (undefined); }
    return resScheduleFrames.find(el => (el.scheduleDate === format(date, 'yyyy-MM-dd')) && (el.frameTime === hour * 2));
  }

  function isRegistered(date: Date, hour: number): boolean {
    return fetchSchduleFrame(date, hour) !== undefined;
  }

  function isReservated(date: Date, hour: number): boolean {
    const frame = fetchSchduleFrame(date, hour);
    if (frame === undefined || counselings === undefined) { return false; }
    return (counselings.some(c => c.scheduleFrameId === frame.id));
  }

  useEffect(() => {
    setLoading(true);
    const reqSchedules = api.counselorCounselorIdScheduleFramesGet(Number(session.principal.id));
    const reqCounselings = counselingApi.counselorCounselingsGet();
    Promise.all([reqSchedules, reqCounselings]).then(([schedulesRes, counselingsRes]) => {
      const frames = schedulesRes.data.scheduleFrames;
      if (frames) {
        setResScheduleFrames(frames);
      }
      setCounselings(counselingsRes.data);
    }).catch(() => {
      setToastMessage('データ取得に失敗しました');
    }).finally(() => {
      setLoading(false);
    });
  }, [session]);

  function registerButton(d: Date, hour: number) {
    return (<input type="checkbox" className={classes.reservationButton} name="schedule_frames" value={`${format(d, 'yyyy-MM-dd')}%${hour}`} />);
  }

  function syncScheduleFrames() {
    setLoading(true);
    const req = api.counselorCounselorIdScheduleFramesGet(Number(session.principal.id));
    req.then(res => {
      const frames = res.data.scheduleFrames;
      if (frames) {
        setResScheduleFrames(frames);
      }
    }).catch(() => {
      setToastMessage('登録済みスケジュールの取得に失敗しました');
    }).finally(() => {
      setLoading(false);
    });
  }

  function reservationLink(date: Date, hour: number) {
    const frame = fetchSchduleFrame(date, hour);
    if (frame === undefined || counselings === undefined) { return (<></>); }
    const counseling = counselings.find(c => c.scheduleFrameId === frame.id);
    return (
      <Button
        variant="contained"
        onClick={() => history.push(`/counselor/counselings/${counseling && counseling.counselingReservationId}`)
      }
      >
      面談
      </Button>
    );
  }

  function handleSubmit(e: FormEvent) {
    setLoading(true);
    e.preventDefault();
    // Reactでやっていいことじゃないのはよくわかってるけど緊急避難としてdomを参照してる。
    // 経緯的には項目チェックをstateで管理した時に頻繁なレンダリングを抑制できず尋常じゃないラグが発生したため。
    // あとで適切にRenderを抑制してコンポーネント化なりしてリファクタリングを行う。
    const checks: string[] = [];
    document.getElementsByName('schedule_frames').forEach((el: any, _key) => {
      if (el.checked) {
        checks.push(el.value);
      }
    });

    const scheduleFrames = checks.map(check => {
      const date = check.split('%')[0];
      const hour = Number(check.split('%')[1]);
      return { date, hour };
    });

    const reqSchedules: Array<ScheduleFrameRequest> = scheduleFrames
      .map(schedule => ({ scheduleDate: schedule.date, frameTime: schedule.hour * 2 }));
    api.counselorCounselorIdScheduleFramesPost(
      Number(session.principal.id),
      { scheduleFrames: reqSchedules },
    ).then(() => {
      syncScheduleFrames();
      setToastMessage(`登録しました (${format(new Date(), 'HH時mm分ss秒')})`);
    })
      .catch(() => {
        setToastMessage(`登録失敗しました (${format(new Date(), 'HH時mm分ss秒')})`);
      }).finally();
  }

  function frameContent(d: Date, hour: number) {
    if (isReservated(d, hour)) { return reservationLink(d, hour); }
    if (isRegistered(d, hour)) {
      return (
        <>
          <CheckIcon />
        </>
      );
    }
    if (isEnableDay(d)) { return registerButton(d, hour); }
    return '';
  }

  if (loading) { return (<CircularProgress size={100} />); }
  return (
    <>
      <Box display="flex">
        <Box flexGrow={1}>
          <Typography variant="h4" component="h1">
            スケジュール管理
          </Typography>
        </Box>
      </Box>

      <form onSubmit={handleSubmit}>
        <Paper>
          <AppBar position="static">
            <Tabs value={tabValue} onChange={handleChange} aria-label="simple tabs">
              {daysOfOperableMonth.map((day, index) => (
                <Tab label={format(day, 'yyyy/MM')} {...a11yProps(index)} className={classes.tab} key={day.toString()} />))
            }
            </Tabs>
          </AppBar>
          {daysOfOperableMonth.map((day, index) => (
            <TabPanel value={tabValue} index={index} key={day.toString()}>
              <Table aria-label="simple table">
                <TableBody>
                  {(eachDayOfInterval(
                    { start: startOfMonth(day), end: endOfMonth(day) },
                  )).map(d => (
                    <TableRow key={d.toString()}>
                      <TableCell className={classes.cell}>{format(d, 'dd(E)', { locale: ja })}</TableCell>
                      {selectableHours.map(hour => (
                        <TableCell className={classes.cell} key={`${d.toString()}:${hour}`}>
                          {hour}
:00-
                          <br />
                          {frameContent(d, hour)}
                        </TableCell>
                      ))}
                    </TableRow>
                  ))
              }
                </TableBody>
              </Table>
            </TabPanel>
          ))}
        </Paper>
        <Box display="flex">
          <p>
            <Button
              variant="contained"
              color="primary"
              type="submit"
            >
            選択した枠を登録する
            </Button>
          </p>
        </Box>
      </form>
      <Toast
        message={toastMessage}
        duration={3000}
      />
    </>
  );
};

export const ScheduleFramePostPage = SideMenuLayout(withAuth(Content));
