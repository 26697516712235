import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import {
  CssBaseline,
  Container,
  AppBar,
  Toolbar,
  Box,
  Typography,
  Fab,
  Paper,
  Popper,
  MenuList,
  MenuItem,
  ClickAwayListener,
}
  from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import { AdminNavigation } from '../components/admin/AdminNavigation';
import { CounselorNavigation } from '../components/counselor/CounselorNavigation';
import { clearSession } from '../redux/session/SessionAction';
import { useSession } from '../foundation/Store';

const drawerWidth = 240;

const styles = makeStyles(theme => ({
  root: {
    display: 'flex',
  },
  appBar: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
  },
  toolbar: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.default,
    padding: theme.spacing(3),
  },
  userName: {
    fontWeight: 'bold',
    margin: '20px',
    color: '#fff',
  },
  title: {
    fontWeight: 'bold',
    color: '#fff',
    fontSize: '2em',
  },
}));

export const SideMenuLayout = (Component: React.ComponentType) => function WrappedLayout() {
  const classes = styles({});
  const dispatch = useDispatch();
  const session = useSession();
  const [open, setOpen] = useState(false);
  const anchorRef = React.useRef<HTMLButtonElement>(null);

  const logout = () => dispatch(clearSession());

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar position="fixed" className={classes.appBar}>
        <Toolbar>
          <Box flexGrow={1}>
            <Typography className={classes.title}>
              { (session !== 'guest' && session.principal.role === 'ADMIN') ? '管理者' : 'カウンセラー'}
            </Typography>
          </Box>
          <Box>
            <Typography className={classes.userName}>
              {session !== 'guest' && `${session.principal.name} 様`}
            </Typography>
          </Box>
          <Box>
            <Fab
              ref={anchorRef}
              size="small"
              onClick={() => setOpen(!open)}
              style={{ background: 'white' }}
            >
              <MenuIcon />
            </Fab>
            <Popper
              open={open}
              anchorEl={anchorRef.current}
              transition
              disablePortal
            >
              <Paper style={{ margin: '10px' }}>
                <ClickAwayListener onClickAway={() => setOpen(false)}>
                  <MenuList>
                    <MenuItem onClick={logout}>ログアウト</MenuItem>
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Popper>
          </Box>
        </Toolbar>
      </AppBar>
      {(() => {
        if (session === 'guest') {
          return (<></>);
        } if (session.principal.role === 'ADMIN') {
          return (<AdminNavigation />);
        } if (session.principal.role === 'COUNSELOR') {
          return (<CounselorNavigation />);
        } return (<></>);
      })()}
      <Container className={classes.content}>
        <div className={classes.toolbar} />
        <Component />
      </Container>
    </div>
  );
};
