import { createMuiTheme } from '@material-ui/core/styles';

const palette = {
  primary: {
    main: '#A6B1E1',
    dark: '#424874E',
  },
  secondary: {
    main: '#FFCAD4',
  },
  background: {
    default: '#FAFAFF',
  },
};

export const theme = createMuiTheme({
  palette,
  overrides: {
    MuiButton: {
      root: {
        textTransform: 'none',
      },
      containedPrimary: {
        color: 'white',
        fontWeight: 'bold',
      },
      containedSecondary: {
        color: 'white',
        fontWeight: 'bold',
      },
    },
    MuiLink: {
      root: {
        color: palette.primary.dark,
      },
    },
  },
  props: {
    MuiButton: {
      variant: 'contained',
    },
    MuiTextField: {
      variant: 'outlined',
    },
    MuiLink: {
      underline: 'hover',
    },
  },
});
