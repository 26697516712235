import React from 'react';
import {
  Paper, Theme, makeStyles, createStyles, Box, Grid, Typography, Button,
} from '@material-ui/core';
import { ScheduleFrameResponse } from '../../generated/api';

const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {
    marginTop: theme.spacing(1),
    padding: 10,
  },
  box: {
    textAlign: 'center',
    margin: 2,
  },
}));

interface IProps {
  scheduleFrames?: ScheduleFrameResponse[];
  deleteMethod: (frameId: number) => void;
}

const Content = React.memo(({ scheduleFrames, deleteMethod }: IProps) => {
  const classes = useStyles({});
  if (!scheduleFrames) { return (<></>); }
  return (
    <Paper className={classes.root}>
      <Typography variant="h6" component="h3">{scheduleFrames[0].scheduleDate}</Typography>
      <Grid container>
        {scheduleFrames.sort((a, b) => a.frameTime - b.frameTime).map(frame => (
          <Grid item xs={1} key={frame.id}>
            <Box border={1} className={classes.box}>
              {frame.frameTime / 2}
:00
              <br />
              <Button size="small" variant="text" onClick={() => { deleteMethod(frame.id); }}>取消</Button>
            </Box>
          </Grid>
        ))}
      </Grid>
    </Paper>

  );
}, (prevProps, nextProps) => prevProps.scheduleFrames === nextProps.scheduleFrames);

export const ScheduleFramesByDay = Content;
