import React from 'react';
import { Link, LinkProps } from 'react-router-dom';

// Material UIでButtonのRoutingを行うためのモジュール
// @seealso https://material-ui.com/guides/composition/

// LinkのPropsと衝突しないように親のpropsをOmit
type UncollidableProps = Omit<LinkProps, 'innerRef' | 'to'>;

export const LinkForward = (path: string) => React.forwardRef<HTMLAnchorElement, UncollidableProps>(
  (props, ref) => <Link innerRef={ref as any} to={path} {...props} />,
);
