import React, { useState, useEffect } from 'react';
import {
  Button,
  Box,
  Typography,
  Paper,
  CircularProgress,
  Select,
  MenuItem,
  FormLabel,
} from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import useReactRouter from 'use-react-router';
import DateFnsUtils from '@date-io/date-fns';
import { makeStyles } from '@material-ui/core/styles';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import { Toast } from '../../../components/common/Toast';
import { SideMenuLayout } from '../../../layouts';
import {
  UpdateCounselingReservationRequest,
  CounselingApi,
  CounselorResponse,
} from '../../../generated';
import { withAuth, AuthedComponentProps } from '../../../auth/Authenticated';
import { usePrivateApi } from '../../../api/useApi';

const useStyles = makeStyles(() => ({
  paper: {
    padding: 20,
  },
  selectMenu: {
    width: 100,
  },
  box: {
    padding: 20,
  },
}));

const Content: React.FunctionComponent<AuthedComponentProps> = (
  { session }: AuthedComponentProps,
) => {
  const { match, history } = useReactRouter<{ id: string }>();

  const { api, loading } = usePrivateApi(CounselingApi);
  const [counselors, setCounselors] = useState<CounselorResponse[]>();
  const [selectedDate, setSelectedDate] = useState<Date|null>(null);
  const [selectedHour, setSelectedHour] = useState<number|null>(null);
  const [selectedCounselorId, setSelectedCounselorId] = useState<number|null>(null);
  const [toastMessage, setToastMessage] = useState(String);
  const selectableHours = [...Array(23)].map((_, i) => (i));

  const handleDateChange = (date: Date | null) => {
    setSelectedDate(date);
  };
  const handleHourChange = (e: React.ChangeEvent<{ value: any }>) => {
    setSelectedHour(e.target.value as number);
  };
  const handleCounselorChange = (e: React.ChangeEvent<{ value: any }>) => {
    setSelectedCounselorId(e.target.value as number);
  };

  useEffect(() => {
    const counselorRequest = api.adminCounselorsGet();
    Promise.all([counselorRequest])
      .then(([counselorRes]) => {
        setCounselors(counselorRes.data);
      });
  }, [match, session]);

  const onSubmit = () => {
    if (!(selectedHour && selectedDate && selectedCounselorId)) {
      setToastMessage(`予定を変更するにはすべての項目を埋める必要があります(${new Date()})`);
      return;
    }
    const updateCounselingReservation: UpdateCounselingReservationRequest = {
      reservationHour: selectedHour,
      reservationDate: selectedDate.toJSON(),
      counselorId: selectedCounselorId,
    };
    api.adminCounselingsReservationReservationIdPut(
      Number(match.params.id), updateCounselingReservation,
    ).then(_res => history.push(`/admin/counselings/${match.params.id}`)).catch(_e => setToastMessage(`登録エラーが発生しました。対象時間帯は既に予約されている可能性があります (${new Date()}時点)`));
  };


  const classes = useStyles();
  if (loading) return <CircularProgress />;
  if (!loading && counselors) {
    return (
      <>
        <Typography variant="h4" component="h1">
          面談予約編集ページ
        </Typography>
        <Paper className={classes.paper}>
          <Box className={classes.box}>
            <FormLabel component="legend">予約日</FormLabel>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <KeyboardDatePicker
                disableToolbar
                variant="inline"
                format="yyyy年MM月dd日"
                margin="normal"
                id="date-picker-inline"
                label="予約日の指定"
                value={selectedDate}
                onChange={handleDateChange}
                KeyboardButtonProps={{
                  'aria-label': 'change date',
                }}
              />
            </MuiPickersUtilsProvider>
          </Box>
          <Box className={classes.box}>
            <FormLabel component="legend">予約時間帯</FormLabel>
            <Select
              name="hour"
              value={selectedHour}
              onChange={handleHourChange}
              className={classes.selectMenu}
            >
              { selectableHours.map(hour => (
                <MenuItem value={hour}>
                  {hour}
時 -
                </MenuItem>
              ))}
            </Select>
          </Box>

          <Box className={classes.box}>
            <FormLabel component="legend">カウンセラー</FormLabel>
            <Select
              name="counselorId"
              value={selectedCounselorId}
              onChange={handleCounselorChange}
              className={classes.selectMenu}
            >
              { counselors && counselors.map(counselor => (
                <MenuItem value={counselor.id}>
                  {counselor.name}
                </MenuItem>
              ))}
            </Select>
          </Box>
        </Paper>
        <Box display="flex" mt={2}>
          <Box flexGrow={1} />
          <Box>
            <Button
              variant="contained"
              color="primary"
              onClick={onSubmit}
            >
              <EditIcon />
                  更新する
            </Button>
          </Box>
        </Box>
        <Toast
          message={toastMessage}
          duration={6000}
        />
      </>
    );
  }

  return <p>404 not found</p>;
};

export const AdminCounselingReservationEditPage = SideMenuLayout(withAuth(Content));
