import React from 'react';
import {
  Paper, Table, TableHead, TableRow, TableCell, TableBody, Theme, makeStyles, createStyles, Button,
} from '@material-ui/core';
import useReactRouter from 'use-react-router';
import { OrganizationResponse } from '../../generated/api';

const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {
    width: '100%',
    marginTop: theme.spacing(3),
    overflowX: 'auto',
  },
  table: {
    minWidth: 650,
  },
  textButton: {
    '& > *': {
      margin: theme.spacing(1),
    },
  },
}));

interface IProps {
  organizations?: OrganizationResponse[];
}

const Content = ({ organizations }: IProps) => {
  const classes = useStyles({});
  const { history } = useReactRouter();

  return (
    <Paper className={classes.root}>
      <Table className={classes.table}>
        <TableHead>
          <TableRow>
            <TableCell>企業名</TableCell>
            <TableCell>アクション</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {organizations ? organizations.map(o => (
            <TableRow key={o.id}>
              <TableCell component="th" scope="row">{o.name}</TableCell>
              <TableCell>
                <Button onClick={() => history.push(`/admin/counselings/bulk-new/${o.id}`)}>
                  面談予約作成
                </Button>
                <br />
                <Button onClick={() => history.push(`/admin/counselor-relations/${o.id}`)}>
                  カウンセラー設定
                </Button>
              </TableCell>
            </TableRow>
          )) : <></>}
        </TableBody>
      </Table>
    </Paper>
  );
};

export const OrganizationList = Content;
