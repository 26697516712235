import actionCreatorFactory from 'typescript-fsa';
import { RedirectState } from './RedirectState';

export const UPDATE_REDIRECT = 'UPDATE_REDIRECT';
export const CLEAR_REDIRECT = 'CLEAR_REDIRECT';

export const Actions = {
  UPDATE_REDIRECT,
  CLEAR_REDIRECT,
};

const actionCreator = actionCreatorFactory();
export const updateRedirect = actionCreator<RedirectState>(UPDATE_REDIRECT);
export const clearRedirect = actionCreator(CLEAR_REDIRECT);

export type UpdateRedirect = ReturnType<typeof updateRedirect>;
export type ClearRedirect = ReturnType<typeof clearRedirect>;

export type RedirectAction = UpdateRedirect | ClearRedirect;
