import React, { useEffect, useState } from 'react';
import Typography from '@material-ui/core/Typography';
import {
  CircularProgress, Box, Grid,
} from '@material-ui/core';
import { SideMenuLayout } from '../../../layouts';
import { withAuth, AuthedComponentProps } from '../../../auth/Authenticated';
import { CounselingList } from '../../../components/admin/CounselingList';
import { usePrivateApi } from '../../../api/useApi';
import { CounselingResponse, CounselingApi } from '../../../generated/api';

const Content = ({ session }: AuthedComponentProps) => {
  const [counselings, setCounselings] = useState<CounselingResponse[]>();
  const [loading, setLoading] = useState(false);
  const { api: counselingApi } = usePrivateApi(CounselingApi);

  useEffect(() => {
    setLoading(true);
    const counselingReq = counselingApi.adminCounselingsGet().then(res => {
      setCounselings(res.data);
    });
    Promise.all([counselingReq]).finally(() => {
      setLoading(false);
    });
  }, [session]);
  return (
    <>
      <Box display="flex">
        <Box flexGrow={1}>
          <Typography variant="h4" component="h1">
            面談記録一覧
          </Typography>
        </Box>
      </Box>
      {loading
        ? (
          <Grid container justify="center">
            <CircularProgress />
          </Grid>
        )
        : <CounselingList counselings={counselings} />
      }
    </>
  );
};

export const AdminCounselingIndexPage = SideMenuLayout(withAuth(Content));
