import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import {
  Paper,
} from '@material-ui/core';
import { SideMenuLayout } from '../../layouts';
import { withAuth } from '../../auth/Authenticated';

const useStyles = makeStyles(() => ({
  paper: {
    padding: 10,
    marginBottom: 30,
  },
  p: {
    margin: 5,
  },
}));

const Content = () => {
  const classes = useStyles({});
  return (
    <>
      <Paper className={classes.paper}>
        <Typography variant="h4" component="h2">人事モジュール</Typography>
        <p className={classes.p}>
          【本番環境】
          <a href="https://organization.m3-mindex.com/" target="_blank" rel="noopener noreferrer">https://organization.m3-mindex.com/</a>
        </p>
        <p className={classes.p}>
          【テスト環境】
          <a href="https://mindex-organization-dev.web.app/" target="_blank" rel="noopener noreferrer">https://mindex-organization-dev.web.app/</a>
        </p>
        <p className={classes.p}>
          企業毎にそれぞれアカウントが必要となります。
          <br />
          <ul>
            <li>従業員の一覧・追加・編集</li>
            <li>アンケート配信の一覧・追加・編集</li>
            <li>アンケート結果の一覧・追加・編集</li>
            <li>アラートの一覧</li>
            <li>面談予約/記録の一覧・追加</li>
          </ul>
        </p>
      </Paper>
      <Paper className={classes.paper}>
        <Typography variant="h4" component="h2">管理モジュール</Typography>
        <p className={classes.p}>
          【本番環境】
          <a href="https://admin.m3-mindex.com/" target="_blank" rel="noopener noreferrer">https://admin.m3-mindex.com/</a>
        </p>
        <p className={classes.p}>
          【テスト環境】
          <a href="https://mindex-admin-dev.web.app/" target="_blank" rel="noopener noreferrer">https://mindex-admin-dev.web.app/</a>
        </p>
        <p className={classes.p}>
          アカウントの種類には「管理者」と「カウンセラー」があり、それぞれで出来ることが違います。
          <br />
          <br />
          <Typography variant="h6" component="h3">管理者</Typography>
          <ul>
            <li>面談予約の一覧・変更</li>
          </ul>
          <br />
          <Typography variant="h6" component="h3">カウンセラー</Typography>
          <ul>
            <li>受付中日時の追加・一覧・取り消し</li>
            <li>面談予約の一覧</li>
            <li>面談記録の一覧・変更</li>
          </ul>
        </p>
      </Paper>

      <Paper className={classes.paper}>
        <Typography variant="h4" component="h2">従業員モジュール</Typography>
        <p className={classes.p}>
          【本番環境】
          <a href="https://employee.m3-mindex.com/" target="_blank" rel="noopener noreferrer">https://employee.m3-mindex.com/</a>
        </p>
        <p className={classes.p}>
          【テスト環境】
          <a href="https://mindex-employee-dev.web.app/" target="_blank" rel="noopener noreferrer">https://mindex-employee-dev.web.app/</a>
        </p>
        <p className={classes.p}>
          <ul>
            <li>アンケートへの回答</li>
            <li>面談予約の日時選択・備考欄の入力</li>
          </ul>
        </p>
        <Typography variant="h6" component="h3">面談予約の入力画面URL</Typography>
        <p className={classes.p}>
          【本番環境】https://employee.m3-mindex.com/counselings/reservation
        </p>
        <p className={classes.p}>
          【テスト環境】https://mindex-employee-dev.web.app/counselings/reservation
        </p>
      </Paper>
    </>
  );
};

export const AdminReadmePage = SideMenuLayout(withAuth(Content));
