import React from 'react';
import { Container } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles({
  main: {
    minHeight: '100vh',
  },
});

export const MainLayout = (Component: React.ComponentType) => function Content() {
  const classes = useStyles();

  return (
    <Container component="main" className={classes.main}>
      <Component />
    </Container>
  );
};
