import React, { useEffect } from 'react';
import {
  FormLabel, FormHelperText, Box,
} from '@material-ui/core';
import { useFormContext } from 'react-hook-form';
import { MuiPickersUtilsProvider, KeyboardDateTimePicker } from '@material-ui/pickers';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import DateFnsUtils from '@date-io/date-fns';
import jaLocale from 'date-fns/locale/ja';
import {
  format, parseISO, isValid, isWithinInterval,
} from 'date-fns';

interface IProps {
  title: string;
  name: string;
  required?: boolean;
  readOnly?: boolean;
}

class LocalizedUtils extends DateFnsUtils {
  getDateTimePickerHeaderText(date: Date) {
    return this.format(date, 'MM/dd');
  }
}

const Content = ({
  title, name, required, readOnly,
}: IProps) => {
  const {
    register, errors, watch, setValue,
  } = useFormContext();
  const error = errors[name];

  const validateDate = (value: string) => {
    if (!value) return true;
    const date = parseISO(value);
    if (!isValid(date)) return false;

    return isWithinInterval(date, { start: new Date(2000, 0, 0), end: new Date(3000, 0, 0) });
  };

  useEffect(() => {
    if (required) {
      register({ name }, { required: '必須項目です', validate: validateDate });
    } else {
      register({ name }, { validate: validateDate });
    }
  }, []);

  const handleChange = (date: MaterialUiPickersDate, value?: string | null) => {
    if (!date) {
      setValue(name, null);
      return;
    }
    try {
      setValue(name, format(date, "yyyy-MM-dd'T'HH:mm"));
    } catch {
      setValue(name, value);
    }
  };

  useEffect(() => {
    if (watch(name) == null) setValue(name, '');
  }, [watch(name)]);

  return (
    <Box p={2}>
      <FormLabel component="legend" required={required}>{title}</FormLabel>
      <MuiPickersUtilsProvider utils={LocalizedUtils} locale={jaLocale}>
        <KeyboardDateTimePicker
          name={name}
          value={watch(name) || null}
          onChange={handleChange}
          readOnly={readOnly}
          minDate="2000-01-01"
          maxDate="3000-01-01"
          format="yyyy/MM/dd HH:mm"
          invalidDateMessage="日付のフォーマットが間違っています"
          minDateMessage="指定した日付は無効です"
          maxDateMessage="指定した日付は無効です"
          ampm={false}
        />
      </MuiPickersUtilsProvider>
      {
        error && (
          <FormHelperText error>
            {error.message}
          </FormHelperText>
        )
      }
    </Box>
  );
};

export const CounselingDateTimeForm = Content;
