import React from 'react';
import useReactRouter from 'use-react-router';
import { AuthedComponentProps, withAuth } from '../auth/Authenticated';
import { SideMenuLayout } from '../layouts';

const Content = ({ session }: AuthedComponentProps) => {
  const { history } = useReactRouter();

  if (session.principal.role === 'ADMIN') { history.push('/admin/readme'); } else { history.push('/counselor/counselings'); }
  return (<></>);
};

export const TopPage = SideMenuLayout(withAuth(Content));
