import React, { useState, useEffect } from 'react';
import {
  Button, Grid, CircularProgress, Box, Typography,
} from '@material-ui/core';
import { useDispatch } from 'react-redux';
import firebase from 'firebase';
import { clearRedirect } from '../../redux/redirect/RedirectAction';
import { clearSession } from '../../redux/session/SessionAction';

interface IProps {
  actionCode: string;
}

const Content = ({ actionCode }: IProps) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState();

  useEffect(() => {
    firebase.auth().applyActionCode(actionCode)
      .catch(error => {
        switch (error.code) {
          case 'auth/expired-action-code':
            setErrorMessage('認証メールの有効期限が切れています');
            break;
          case 'auth/invalid-action-code':
            setErrorMessage('すでに認証済みか、認証用コードが無効です');
            break;
          case 'auth/user-disabled':
            setErrorMessage('ユーザは無効です');
            break;
          case 'auth/user-not-found':
            setErrorMessage('ユーザが見つかりません');
            break;
          default:
            setErrorMessage('メール認証に失敗しました');
        }
      }).finally(() => {
        setLoading(false);
      });
  }, [actionCode]);

  const redirect = () => {
    dispatch(clearRedirect());
    dispatch(clearSession());
    window.location.href = '/';
  };

  if (loading) {
    return (
      <Grid
        container
        spacing={2}
        alignContent="center"
        justify="center"
        style={{ minHeight: '100vh' }}
      >
        <CircularProgress />
      </Grid>
    );
  }

  if (errorMessage) {
    return (
      <Grid
        container
        spacing={2}
        alignContent="center"
        justify="center"
        style={{ minHeight: '100vh' }}
      >
        <Grid
          item
          style={{
            background: 'white',
            padding: '20px',
            paddingTop: '10px',
            borderRadius: '5px',
            width: '500px',
          }}
        >
          <Box m={2}>
            <Box m={1}>
              <Typography variant="h6" component="h3">
                メール認証失敗
              </Typography>
            </Box>
            <Box m={1}>
              {errorMessage}
            </Box>
          </Box>
        </Grid>
      </Grid>
    );
  }

  return (
    <Grid
      container
      spacing={2}
      alignContent="center"
      justify="center"
      style={{ minHeight: '100vh' }}
    >
      <Grid
        item
        style={{
          background: 'white',
          padding: '20px',
          paddingTop: '10px',
          borderRadius: '5px',
          width: '500px',
        }}
      >
        <Box m={2}>
          <Box m={1}>
            <Typography variant="h6" component="h3">
              メール認証完了
            </Typography>
          </Box>
          <Box m={1}>
            <p>メール認証が完了しました。</p>
            <p>ログイン画面から再度ログインしてください。</p>
          </Box>
          <Box m={1}>
            <Button color="primary" onClick={redirect}>元のページに戻る</Button>
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
};

export const VerifyEmail = Content;
